import {Main} from 'components/Layout'
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from 'react'
import {apiCall, embeddedPartnerApi} from 'api'
import {
	ApiEmbeddedPartnerTransaction,
	ApiEmbeddedPartnerTransactionCryptoCurrencyTransactTypeEnum as CryptoCurrencyTransactTypeEnum,
} from '@bakkt/api'
import {getCryptoCurrencyDetails} from 'constants/currencies'
import {DetailSection} from './DetailSection'
import {useStore} from 'store'
import {Page, ActivityHeader, Icons} from '@bakkt/components'
import {formatUSD} from 'utils/currencyFormatters'

export const TransactionDetails = () => {
	const {t} = useTranslation()
	const [transactionInfo, setTransactionInfo] = useState<ApiEmbeddedPartnerTransaction | null>(null)
	const [loading, setLoading] = useState(true)
	const {activePartnerTransactionId} = useStore()
	useEffect(() => {
		if (activePartnerTransactionId) {
			;(async () => {
				const {response} = await apiCall(() =>
					embeddedPartnerApi.getTransactionDetails(activePartnerTransactionId),
				)
				setLoading(false)
				setTransactionInfo(response || null)
			})()
		}
	}, [activePartnerTransactionId])

	const transactionType = transactionInfo?.cryptoCurrencyTransactType
	const crypto =
		transactionType === CryptoCurrencyTransactTypeEnum.BUY
			? transactionInfo?.target
			: transactionType === CryptoCurrencyTransactTypeEnum.SELL
			? transactionInfo?.source
			: null

	const fiatAmount =
		transactionType === CryptoCurrencyTransactTypeEnum.BUY
			? transactionInfo?.source
			: transactionType === CryptoCurrencyTransactTypeEnum.SELL
			? transactionInfo?.target
			: null

	const currencyDetails = getCryptoCurrencyDetails(crypto?.currency)

	return (
		<Page
			withBackIcon
			titleHeader={`${transactionType === CryptoCurrencyTransactTypeEnum.BUY ? t('purchased') : t('sold')} ${
				currencyDetails?.name
			}`}
			loading={loading}
		>
			<Main>
				<ActivityHeader
					icon={transactionType === CryptoCurrencyTransactTypeEnum.BUY ? Icons.BuyCrypto : Icons.SellCrypto}
					activityText={
						transactionType === CryptoCurrencyTransactTypeEnum.BUY
							? t('activity.youPurchased')
							: t('activity.youSold')
					}
					amount={`${crypto?.amount} ${crypto?.currency}`}
					conversionAmount={`${t('for')}  ${
						fiatAmount?.amount && formatUSD(fiatAmount?.amount || 0, fiatAmount?.currency)
					}`}
				/>
				<DetailSection
					currencyDetails={currencyDetails}
					transactionInfo={transactionInfo}
					fiatAmount={fiatAmount}
					transactionType={transactionType}
					crypto={crypto}
				/>
			</Main>
		</Page>
	)
}
