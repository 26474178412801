import React, {CSSProperties, FC, HTMLAttributes, ReactNode} from 'react'
import styled from '@emotion/styled'
import {color} from '../..'

export interface NumberedListProps extends HTMLAttributes<HTMLOListElement> {
	data: any[]
	listItem?: (item: any) => ReactNode | string
	listItemStyle?: CSSProperties
}

const OrderedList = styled.ol`
	margin: 0;
	padding: 0 17px;
`

const ListItem = styled.li`
	margin: 15px 0;
	color: ${props => props.theme?.palette?.text?.primary || color.black};
	font-size: 14px;
`

export const NumberedList: FC<NumberedListProps> = ({data, listItem = item => item, listItemStyle, ...prop}) => (
	<>
		<OrderedList {...prop}>
			{data &&
				Array.isArray(data) &&
				data.map((item, i) => {
					return (
						<ListItem key={i} style={listItemStyle}>
							{listItem(item)}
						</ListItem>
					)
				})}
		</OrderedList>
	</>
)
