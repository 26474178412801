import React from 'react'
import {H1} from '@bakkt/components'
import {formatUSD} from 'utils/currencyFormatters'
import {ApiAmount, ApiEmbeddedCryptoPriceResponse} from '@bakkt/api'

export interface IndicativePriceDisplayProps {
	selectedCurrency?: ApiAmount['currency']
	indicativePrice?: ApiEmbeddedCryptoPriceResponse['indicativePrice']
}

export const IndicativePriceDisplay = ({selectedCurrency, indicativePrice}: IndicativePriceDisplayProps) => {
	const indicativePriceSplit = indicativePrice
		? formatUSD(indicativePrice?.amount, indicativePrice?.currency).split('.')
		: ''

	return (
		<H1 style={{margin: 0}} id='IndicativePriceDisplay'>
			{`1 ${selectedCurrency} = ${indicativePriceSplit[0] || 'N/A'}`}.
			<span id='IndicativePriceDisplay-Cents'>{`${
				indicativePriceSplit[1] || (indicativePriceSplit[0] ? '00' : '')
			}`}</span>
		</H1>
	)
}
