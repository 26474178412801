import React from 'react'
import {useTranslation} from 'react-i18next'

import {Main, BottomBox} from 'components/Layout'
import {Page, Large, H2} from '@bakkt/components'
import Button from 'components/Button'
import {RoutesName} from 'constants/routes'
import {Link} from 'components/Link'
import {Image} from 'components/Image'
import signUpImage from 'images/signUp.svg'
import {useNavigate} from 'hooks'
import ExitOnboardingButton from 'components/ExitOnboardingButton'

const Taxpayer = () => {
	const navigate = useNavigate()
	const {t} = useTranslation()

	return (
		<Page titleHeader={t('crypto.accountActivate.activateCrypto')}>
			<Main centerText>
				<Image url={signUpImage} />
				<H2 data-testid={'section-title'} style={{fontSize: '21px'}}>
					{t('crypto.accountActivate.taxpayerTitle')}
				</H2>
				<Large data-testid={'section-description'} style={{marginTop: 0}}>
					{t('crypto.accountActivate.taxpayerDes')}
				</Large>
				<Link
					id='WhyBakktNeed'
					to={`${RoutesName.onboarding.home}${RoutesName.onboarding.taxpayer}`}
					aria-label='Why does Bakkt need this'
					text={t('crypto.accountActivate.whyBakktNeed')}
				/>
			</Main>
			<BottomBox>
				<Button
					id='TaxpayerContinueButton'
					aria-label='Continue'
					onClick={() =>
						navigate(`${RoutesName.onboarding.home}${RoutesName.onboarding.taxpayerCertification}`, {
							ignore: true,
						})
					}
				>
					{t('bntReady')}
				</Button>
				<ExitOnboardingButton style={{marginBottom: '50px'}} />
			</BottomBox>
		</Page>
	)
}
export default Taxpayer
