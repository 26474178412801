import React from 'react'

import {MinimalList} from 'components/List'
import {NA} from 'components/Text'
import {nameFormatter} from 'utils/formatters'
import {useTranslation} from 'react-i18next'
import {EmbeddedWebState} from 'store'

export interface TaxpayerInformationListProps {
	partyProfile: EmbeddedWebState['partyProfile']
	missingProfileFields: EmbeddedWebState['missingProfileFields']
}

export const TaxpayerInformationList = ({partyProfile, missingProfileFields}: TaxpayerInformationListProps) => {
	const {t} = useTranslation()
	const concatedName = nameFormatter(
		partyProfile?.Party?.firstName || missingProfileFields?.firstName,
		partyProfile?.Party?.lastName || missingProfileFields?.lastName,
	)

	return (
		<MinimalList
			data={[
				{
					primaryText: t('inputLabel.name'),
					contentRight: concatedName || <NA />,
				},
				{
					primaryText: t('inputLabel.businessName'),
					contentRight: <NA />,
				},
				{
					primaryText: t('inputLabel.federal'),
					contentRight: 'Individual',
				},
				{
					primaryText: t('inputLabel.exemptions'),
					contentRight: <NA />,
				},
				{
					primaryText: t('inputLabel.addressLine', {number: 1}),
					contentRight: partyProfile?.Party?.address?.streetLine1 ||
						missingProfileFields?.address?.streetLine1 || <NA />,
				},
				{
					primaryText: t('inputLabel.addressLine', {number: 2}),
					contentRight: partyProfile?.Party?.address?.streetLine2 ||
						missingProfileFields?.address?.streetLine2 || <NA />,
				},
				{
					primaryText: t('inputLabel.city'),
					contentRight: partyProfile?.Party?.address?.locality || missingProfileFields?.address?.locality || (
						<NA />
					),
				},
				{
					primaryText: t('inputLabel.state'),
					contentRight: partyProfile?.Party?.address?.region || missingProfileFields?.address?.region || (
						<NA />
					),
				},
				{
					primaryText: t('inputLabel.zipCode'),
					contentRight: partyProfile?.Party?.address?.postalCode ||
						missingProfileFields?.address?.postalCode || <NA />,
				},
				{
					primaryText: t('inputLabel.requesterNameAddress'),
					contentRight: <NA />,
				},
				{
					primaryText: t('inputLabel.accountNumbers'),
					contentRight: <NA />,
				},
			]}
			capitalize
		/>
	)
}
