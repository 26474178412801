import {useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {useStore} from 'store'
import {useNavigate} from 'hooks'
import {RoutesName} from 'constants/routes'

const {fetchAccounts} = embeddedPartnerApi

export const useCryptoAccount = (navigateToOverview?: boolean) => {
	const {
		cryptoAccounts,
		setCryptoAccounts,
		setSelectedCryptoAccount,
		setSelectedCryptoAccountId,
		selectedCryptoAccountId,
		selectedCryptoAccount,
	} = useStore()
	const navigate = useNavigate()
	const {request, response, loading} = ApiHook(() => fetchAccounts())

	useEffect(() => {
		setCryptoAccounts(response || [])
	}, [response])

	useEffect(() => {
		const cryptoAccountsLength = (cryptoAccounts || []).length

		if (cryptoAccountsLength > 0) {
			if (cryptoAccountsLength === 1) {
				// if no of crypto accounts is equal to 1 then select the first one and redirect to the overview page
				setSelectedCryptoAccount(cryptoAccounts?.[0])
				setSelectedCryptoAccountId(cryptoAccounts?.[0]?.accountId)
				if (navigateToOverview) navigate(RoutesName.crypto.overview, {replace: true})
				return
			}

			if (selectedCryptoAccountId && !selectedCryptoAccount) {
				const selectedAccount = cryptoAccounts.find(account => account.accountId === selectedCryptoAccountId)
				if (selectedAccount) setSelectedCryptoAccount(selectedAccount)
			}
		}
	}, [cryptoAccounts])

	useEffect(() => {
		request()
	}, [])

	return {
		loading: (cryptoAccounts || []).length === 0 ? loading : false,
	}
}
