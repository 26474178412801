import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {BottomBox, Main} from 'components/Layout'
import {IndicativePriceDisplay} from 'components/IndicativePriceDisplay'
import {
	CircleIcon,
	H1,
	HBox,
	Icons,
	Large,
	Light,
	Page,
	Separator,
	TimeSeriesChart,
	VBox,
	color,
	PoweredBy,
} from '@bakkt/components'
import {
	useCryptoAccount,
	useCryptoAccountBalance,
	useCryptoCurrencyPrices,
	useCryptoHistoricalCurrency,
	useNavigate,
} from 'hooks'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {RoutesName} from 'constants/routes'
import {HistoricalPeriod, useStore} from 'store'
import {useSelectedCryptoAccountInfo} from 'hooks/useSelectedCryptoAccountInfo'
import TransactionActivity from '../transaction/activity/TransactionActivity'

export const CoinOverview = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {selectedCryptoAccount, setCryptoTransactionConfirm, cryptoAccounts} = useStore()
	const {selectedCryptoCurrencyName} = useSelectedCryptoAccountInfo()
	const {historicalCryptoCurrency, changeTimeRange} = useCryptoHistoricalCurrency()
	useCryptoAccountBalance()
	const {cryptoCurrencyPrice} = useCryptoCurrencyPrices()
	const {accountBalance, indicativeBalance} = selectedCryptoAccount || {}
	const isBuyDisabled = !indicativeBalance
	const isSellDisabled = !indicativeBalance || !accountBalance?.amount
	const [change, setChange] = useState<number>(0)
	const {loading} = useCryptoAccount()

	useEffect(() => {
		setCryptoTransactionConfirm(null)
	}, [])

	useEffect(() => {
		if (!historicalCryptoCurrency.length) return
		const oldest = historicalCryptoCurrency[0]
		const newest = historicalCryptoCurrency[historicalCryptoCurrency.length - 1] || oldest
		const change = (((Number(newest.price) - Number(oldest.price)) / Number(oldest.price)) * 100).toFixed(2)
		setChange(Number(change))
	}, [historicalCryptoCurrency])

	//TODO cryptoBalance can have ETH, and other crypto balance data as well, which we can decide based on url like crypto/btc/overview or crypto/eth/overview
	//TODO currently mocking it with some data. once api is there, we can integrate
	//TODO we need to refactor it with TimeRange enum, when we actually work on this
	const handlePriceChartTimeRange = (timeRange: HistoricalPeriod) => {
		changeTimeRange(timeRange)
	}

	return (
		<Page
			withBackIcon
			onIconPress={() => (cryptoAccounts.length > 1 ? navigate(RoutesName.home) : window.history.go(-1))}
			titleHeader={selectedCryptoCurrencyName}
			loading={loading}
		>
			<Main>
				<H1
					data-testid={'crypto-balance'}
					style={{textAlign: 'center', margin: 'auto', fontSize: 36, marginBottom: 5}}
				>
					{accountBalance ? formatCrypto(accountBalance?.amount, accountBalance?.currency) : 'N/A'}
				</H1>
				<Large data-testid={'usd-balance'} style={{textAlign: 'center', margin: 'auto', fontSize: 18}}>
					{indicativeBalance ? formatUSD(indicativeBalance?.amount, indicativeBalance?.currency) : '-'}
				</Large>
				<HBox style={{justifyContent: 'center', marginTop: 30, marginBottom: 40}}>
					<VBox style={{alignItems: 'center', marginRight: 40}}>
						<CircleIcon
							icon={Icons.BuyCrypto}
							iconColor={isBuyDisabled ? color.grey500 : undefined}
							style={{
								cursor: isBuyDisabled ? 'not-allowed' : 'pointer',
								height: 50,
								width: 50,
								background: isBuyDisabled ? color.grey100 : undefined,
							}}
							id='overview-buy-button'
							onClick={() => !isBuyDisabled && navigate(`/${RoutesName.crypto.buy}`, {ignore: true})}
							disabled={isBuyDisabled}
						/>
						<Large>{t('buy')}</Large>
					</VBox>
					<VBox style={{alignItems: 'center'}}>
						<CircleIcon
							icon={Icons.SellCrypto}
							iconColor={isSellDisabled ? color.grey500 : undefined}
							style={{
								cursor: isSellDisabled ? 'not-allowed' : 'pointer',
								height: 50,
								width: 50,
								background: isSellDisabled ? color.grey100 : undefined,
							}}
							id='overview-sell-button'
							onClick={() => !isSellDisabled && navigate(`/${RoutesName.crypto.sell}`, {ignore: true})}
							disabled={isSellDisabled}
						/>
						<Large>{t('sell')}</Large>
					</VBox>
				</HBox>
				<Separator />
				{accountBalance?.currency && (
					<VBox>
						<Light className='coinName' style={{margin: '10px 0px', fontSize: 20}}>{`${t(
							`currencies.${accountBalance?.currency}`,
						)} ${accountBalance?.currency || ''}`}</Light>
						<IndicativePriceDisplay
							selectedCurrency={accountBalance?.currency}
							indicativePrice={cryptoCurrencyPrice?.indicativePrice}
						/>
						<Light className='changeIcon'>
							{change >= 0 ? <Icons.GoesUp id='UpArrow' /> : <Icons.GoesDown id='DownArrow' />}
							{`${change}%`}
						</Light>
					</VBox>
				)}
				<TimeSeriesChart data={historicalCryptoCurrency} onTimeRangeChange={handlePriceChartTimeRange} />
				<TransactionActivity />
			</Main>
			<BottomBox>
				<PoweredBy text={t('poweredBy')} />
			</BottomBox>
		</Page>
	)
}
