import {ButtonHTMLAttributes} from 'react'
import {ApiAmount} from '@bakkt/api'
import {getCryptoCurrencyDetails} from 'constants/currencies'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {Icons, color, HBox, VBox, Light, Large} from '@bakkt/components'

interface CryptoAccountItemProps extends ButtonHTMLAttributes<HTMLDivElement> {
	accountBalance?: ApiAmount
	indicativeBalance?: ApiAmount
	endIcon?: React.ReactElement
}

const CryptoAccountItem = ({accountBalance, indicativeBalance, endIcon, ...props}: CryptoAccountItemProps) => {
	const cryptoCurrency: string = accountBalance?.currency || ''
	const currencyDetail = getCryptoCurrencyDetails(cryptoCurrency)
	const currencyName = currencyDetail ? currencyDetail?.name : cryptoCurrency
	const cryptoAccountImage = currencyDetail ? currencyDetail?.icon : null
	const testIdPrefix = currencyName.toLowerCase().replace(/\s/g, '-')

	return (
		<div
			data-testid={`${testIdPrefix}-row`}
			style={{
				cursor: 'pointer',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				borderBottom: `1px solid ${color.grey100}`,
				padding: '16px 0px',
			}}
			{...props}
		>
			<HBox horizontalGap={15}>
				{cryptoAccountImage && cryptoAccountImage({})}
				<Large data-testid={`${testIdPrefix}-label`} style={{margin: 'auto'}}>
					{currencyName}
				</Large>
			</HBox>
			<div style={{display: 'inline-flex', alignItems: 'center'}}>
				<HBox horizontalGap={15}>
					<VBox verticalGap={1}>
						<Light
							data-testid={`${testIdPrefix}-balance-usd`}
							style={{fontSize: '14px', marginLeft: 'auto', marginBottom: 'auto'}}
						>
							{formatUSD(indicativeBalance?.amount, indicativeBalance?.currency)}
						</Light>
						{accountBalance && (
							<Light
								data-testid={`${testIdPrefix}-balance-crypto`}
								style={{fontSize: '11px', marginBottom: 'auto'}}
							>{`${formatCrypto(accountBalance?.amount, accountBalance?.currency)}`}</Light>
						)}
					</VBox>
					<VBox style={{margin: 'auto'}}>
						{!!endIcon ? endIcon : <Icons.ChevronRightSmll data-testid={`navigate-to-${testIdPrefix}`} />}
					</VBox>
				</HBox>
			</div>
		</div>
	)
}

export default CryptoAccountItem
