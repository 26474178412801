import styled from '@emotion/styled'
import {useTheme} from '@material-ui/core'
import {FC} from 'react'
import {Icons} from 'src'

const PoweredByDiv = styled.div`
	margin-top: 24px;
	color: ${props => props?.theme?.palette?.text?.background || '#231f20'};
	text-decoration: none;
	display: flex;
	align-items: center;
	margin-bottom: 0;
	font-size: 12px;

	.logo {
		margin: 0;
		margin-top: 1px;
		margin-left: 5px;
	}
`

type PoweredByProps = {
	text?: string
}

export const PoweredBy: FC<PoweredByProps> = ({text, ...props}) => {
	const theme = useTheme()

	return (
		<PoweredByDiv className={'poweredBy'} {...props}>
			{text || 'Powered By'}
			<Icons.BakktLogo
				className={'logo'}
				width={58}
				height={22}
				fill={theme?.palette?.text?.background || '#231f20'}
			/>
		</PoweredByDiv>
	)
}
