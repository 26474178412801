import React from 'react'
import {BakktDialogProps, DialogText, Icons, Utils} from '@bakkt/components'

export type DialogProps = {
	title: string
	message?: string
	WrappedComponent?: React.ComponentClass<any> | (() => JSX.Element) | any
	onSave?: () => void
	preventHideDialog?: boolean
} & Partial<BakktDialogProps>

const fullScreenModelStyles = {
	margin: 0,
	borderRadius: 0,
	width: '100%',
	maxWidth: '100%',
	height: '100%',
	maxHeight: '100%',
}

const embeddedWebDialogStyle = {
	fullWidthButtons: true,
	buttonTextSize: 'large',
	dialogStyle: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: '33px',
	},
	cancelButtonStyle: {border: 'none'},
} as Partial<BakktDialogProps>

const embeddedWebFullScreenDialogStyle = {
	...embeddedWebDialogStyle,
	dialogStyle: {...fullScreenModelStyles},
} as Partial<BakktDialogProps>

export const showDialog = async ({
	title,
	WrappedComponent,
	message,
	fullScreen,
	onSave,
	preventHideDialog,
	...props
}: DialogProps) => {
	const state = await Utils.showFuncDialog(
		title,
		() => {
			return !message ? (
				<WrappedComponent />
			) : (
				<DialogText data-testid={'alert-dialog-description'}>{message}</DialogText>
			)
		},
		{
			...(fullScreen ? embeddedWebFullScreenDialogStyle : embeddedWebDialogStyle),
			fullScreen,
			...props,
		},
	)

	if (typeof state === 'undefined' && onSave) onSave()
	if (!preventHideDialog) Utils.hideDialog()
}

export const showWarning = async ({title, message, ...props}: DialogProps) =>
	showDialog({
		title,
		message,
		hideSave: true,
		hideCancel: true,
		skip: true,
		skipText: 'OK',
		icon: Icons.DialogWarning,
		...props,
	})

export const hideDialog = () => {
	Utils.hideDialog()
}
