import {useTranslation} from 'react-i18next'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {FundingRow} from './FundingRow'
import {useStore} from 'store'
import {useSelectedCryptoAccountInfo} from 'hooks/useSelectedCryptoAccountInfo'

type Props = {
	style?: React.CSSProperties
}

export const CryptoAccountRow = ({style = {}}: Props) => {
	const {t} = useTranslation()
	const {selectedCryptoAccount} = useStore()
	const {selectedCryptoCurrencyName, selectedCryptoCurrencyIcon} = useSelectedCryptoAccountInfo()
	const indicativeBalance = Number(selectedCryptoAccount?.indicativeBalance?.amount)
	const indicativeBalanceCurrency = selectedCryptoAccount?.indicativeBalance?.currency
	const accountBalance = Number(selectedCryptoAccount?.accountBalance?.amount)
	const currency = selectedCryptoAccount?.accountBalance?.currency

	const formatedDetails = selectedCryptoAccount
		? `${formatUSD(indicativeBalance, indicativeBalanceCurrency)} | ${formatCrypto(accountBalance, currency)}`
		: 'N/A'

	return (
		<FundingRow
			dataTestId='available'
			label={t('label.available')}
			fundingName={selectedCryptoCurrencyName}
			icon={
				(selectedCryptoCurrencyIcon &&
					selectedCryptoCurrencyIcon({viewBox: '0 0 40 40', width: '22px', height: '22px'})) ||
				null
			}
			fundingDetails={formatedDetails}
			style={style}
		/>
	)
}
