import {CSSProperties, useMemo} from 'react'
import {H1} from './Text'

export type BreakPointOptions = {threshold: number; style: CSSProperties}
export const DEFAULT_BREAK_POINTS = [
	{threshold: 8, style: {fontSize: 36, fontWeight: 700}},
	{threshold: 11, style: {fontSize: 34, fontWeight: 700}},
	{threshold: 14, style: {fontSize: 32, fontWeight: 700}},
	{threshold: 17, style: {fontSize: 30, fontWeight: 700}},
	{threshold: 20, style: {fontSize: 28, fontWeight: 700}},
	{threshold: 23, style: {fontSize: 26, fontWeight: 700}},
	{threshold: 26, style: {fontSize: 24, fontWeight: 700}},
]

type ResponsiveHeaderProps = {
	value: string
	id?: string
	style?: CSSProperties
	breakPoints?: BreakPointOptions[]
}

export const responsiveStyler = (value: string, breakPoints?: BreakPointOptions[]) => {
	if (!breakPoints || breakPoints.length === 0) return {}

	if (value) {
		for (const i in breakPoints) {
			if (value.length <= breakPoints[i].threshold) {
				return breakPoints[i].style
			}
		}
	}

	return breakPoints[breakPoints.length - 1].style || {}
}

export const ResponsiveHeader = ({id, value, style, breakPoints = DEFAULT_BREAK_POINTS}: ResponsiveHeaderProps) => {
	const responsiveStyle = useMemo(() => responsiveStyler(value, breakPoints), [value, breakPoints])

	return (
		<H1 id={id} style={{...style, ...responsiveStyle}}>
			{value}
		</H1>
	)
}
