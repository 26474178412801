import {Suspense, useEffect} from 'react'
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import {ApiEmbeddedPartnerTransactionCryptoCurrencyTransactTypeEnum as TransactionType} from '@bakkt/api'
import {useStore} from 'store'
import Consent from 'apps/onboarding/pages/Consent'
import {CoinOverview} from 'apps/main/pages/crypto/overview'
import {CryptoTransactionInput} from 'apps/main/pages/crypto/transaction/CryptoTransactionInput'
import {CryptoTransactionSuccess} from 'apps/main/pages/crypto/transaction/CryptoTransactionSuccess'
import {SelectFundingAccount} from 'apps/main/pages/crypto/fundingAccount/SelectFundingAccount'
import CryptoAccountActivationSuccess from 'apps/onboarding/pages/ActivationSuccess/ActivationSuccess'
import Taxpayer from 'apps/onboarding/pages/Taxpayer/Taxpayer'
import TaxpayerCertification from 'apps/onboarding/pages/Taxpayer/TaxpayerCertification'
import {PersonalInformationInput} from 'apps/onboarding/pages/PersonalInformation/PersonalInformationInput'
import Progress from 'components/Progress'
import {RoutesName} from 'constants/routes'
import {CryptoTransactionConfirm} from 'apps/main/pages/crypto/transaction/CryptoTransactionConfirm'
import {SelectCryptoAccount} from 'apps/main/pages/crypto/cryptoAccounts/selectCryptoAccounts'
import {useSetupSardine, useSift, useSegment} from 'hooks'
import {TransactionDetails} from 'apps/main/pages/crypto/transaction/detail/TransactionDetails'

const App = () => {
	const {loadOnStartup, party} = useStore()

	useSetupSardine(party?.sardinePartyRef)
	useSegment(party)
	useSift(party?.siftPartyRef)

	useEffect(() => {
		loadOnStartup()
	}, [])

	return (
		<Suspense fallback={<p>Loading...</p>}>
			<Routes>
				<Route
					path={`${RoutesName.onboarding.home}*`}
					element={
						<RequireNotEnrolled>
							<Routes>
								<Route element={<Consent />} path='' />
								<Route element={<PersonalInformationInput />} path={RoutesName.onboarding.kyc} />
								<Route element={<Taxpayer />} path={RoutesName.onboarding.taxpayer} />
								<Route
									element={<TaxpayerCertification />}
									path={RoutesName.onboarding.taxpayerCertification}
								/>
								<Route
									element={<CryptoAccountActivationSuccess />}
									path={RoutesName.onboarding.success}
								/>
							</Routes>
						</RequireNotEnrolled>
					}
				/>
				<Route element={<TransactionDetails />} path={RoutesName.crypto.transaction.detail} />
				<Route
					path={`${RoutesName.home}*`}
					element={
						<RequireAuth>
							<Routes>
								<Route element={<SelectCryptoAccount />} path='/' />
								<Route element={<CoinOverview />} path={RoutesName.crypto.overview} />
								<Route path='buy'>
									<Route
										element={<CryptoTransactionInput transactionType={TransactionType.BUY} />}
										path=''
									/>
									<Route
										element={<SelectFundingAccount transactionType={TransactionType.BUY} />}
										path='funding'
									/>
									<Route
										element={<CryptoTransactionConfirm transactionType={TransactionType.BUY} />}
										path='confirm'
									/>
									<Route
										element={<CryptoTransactionSuccess transactionType={TransactionType.BUY} />}
										path='success'
									/>
								</Route>
								<Route path='sell'>
									<Route
										element={<CryptoTransactionInput transactionType={TransactionType.SELL} />}
										path=''
									/>
									<Route
										element={<SelectFundingAccount transactionType={TransactionType.SELL} />}
										path='funding'
									/>
									<Route
										element={<CryptoTransactionConfirm transactionType={TransactionType.SELL} />}
										path='confirm'
									/>
									<Route
										element={<CryptoTransactionSuccess transactionType={TransactionType.SELL} />}
										path='success'
									/>
								</Route>
							</Routes>
						</RequireAuth>
					}
				/>
			</Routes>
			<div id='popupArea' />
		</Suspense>
	)
}

export default App

const RequireAuth = ({children}: {children: JSX.Element}) => {
	const {isEnrolled, partnerPartyLink, isSuspended} = useStore()
	const location = useLocation()

	if (!partnerPartyLink) {
		// Not Authention SSO yet
		return <Progress />
	}

	if (!isEnrolled) {
		// Authenticated but not Enroll
		return <Navigate to={RoutesName.onboarding.home} state={{from: location}} replace />
	}

	if (isSuspended && location.pathname !== RoutesName.home) {
		return <Navigate to={RoutesName.home} state={{from: location}} replace />
	}

	return children
}

export const RequireNotEnrolled = ({children}: {children: JSX.Element}) => {
	const {isEnrolled, partnerPartyLink} = useStore()
	const location = useLocation()

	if (!partnerPartyLink) {
		// Not Authention SSO yet
		return <Progress />
	}

	if (isEnrolled) {
		// Authenticated but partner is Enrolled
		return <Navigate to={RoutesName.home} state={{from: location}} replace />
	}

	return children
}
