import {BrownPro, color, useTheme} from '@bakkt/components'
import styled from '@emotion/styled'
import {Theme} from '@material-ui/core'

const NAElement = styled.span`
	font-size: 14px;
	font-weight: 700;
`

export const NA = () => {
	const theme = useTheme() as Theme

	return (
		<NAElement
			style={{
				color: theme?.palette?.text?.background || color.black,
				fontFamily: theme?.typography?.fontFamily || BrownPro,
			}}
		>
			N/A
		</NAElement>
	)
}
