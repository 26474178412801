import {ButtonProps} from 'src/components/button/Button'
import {color} from 'src/color/color'

export const spacing = {
	xsmall: '8px',
	small: '12px',
	medium: '18.5px',
	large: '32px',
	xlarge: '48px',
}

export const fontSizes = {
	xsmall: '.875rem',
	small: '1.000rem',
	medium: '1.125rem',
	large: '1.250rem',
	xlarge: '1.375rem',
}

export const height = {
	small: '16px',
	large: '56px',
	medium: '36px',
}

export const minWidth = {
	small: '41px',
	large: '100px',
	medium: '72px',
}

export const buttonSizeProps = {
	small: {
		fontSize: fontSizes.xsmall,
		padding: `0 ${spacing.small}`,
		paddingToolbar: `${spacing.xsmall} ${spacing.small}`,
	},
	medium: {
		fontSize: fontSizes.small,
		padding: `0 ${spacing.medium}`,
		paddingToolbar: `${spacing.medium} ${spacing.medium}`,
	},
	large: {
		fontSize: fontSizes.medium,
		padding: `0 ${spacing.large}`,
		paddingToolbar: `${spacing.large} ${spacing.large}`,
	},
}

export const getBackgroundColor = ({outline, intent, minimal, backgroundColor, theme}: ButtonProps): string => {
	if (outline && intent == 'dialog' && theme?.palette?.background?.default) {
		return theme?.palette?.background?.default
	}

	if (outline || intent == 'link' || minimal) {
		return color.white
	}
	if (intent == 'toolbar') {
		return color.green400
	}
	if (backgroundColor) {
		return backgroundColor
	}
	if (theme?.palette?.secondary?.main) {
		return theme.palette.secondary.main
	}
	return color.green900
}

export const getPadding = ({size, minimal, intent}: ButtonProps): string | number => {
	if (intent == 'toolbar') {
		if (size) {
			return buttonSizeProps[size].paddingToolbar
		} else {
			return buttonSizeProps.medium.paddingToolbar
		}
	}
	if (size) {
		return buttonSizeProps[size].padding
	} else if (intent == 'link' || minimal) {
		return 0
	}
	return buttonSizeProps.medium.padding
}

export const getColor = ({minimal, intent, outline, color: textColor, theme}: ButtonProps): string => {
	if (intent == 'toolbar') {
		return color.white
	}
	if (outline && intent == 'dialog' && theme?.palette?.text?.primary) {
		return theme?.palette?.text?.primary
	}
	if (minimal || intent == 'link' || outline) {
		return color.blue900
	}
	if (textColor) {
		return textColor
	}
	if (theme?.palette?.secondary?.contrastText) {
		return theme.palette.secondary.contrastText
	}
	return color.success
}

export const getBorderRadius = ({minimal, intent}: ButtonProps): string | number => {
	if (intent == 'toolbar') {
		return '50%'
	}
	if (minimal || intent == 'link') {
		return 0
	}
	return '99px'
}

export const getButtonHeight = ({size = 'medium'}: ButtonProps) => {
	return height[size]
}

export const getButtonWidth = ({size = 'medium'}: ButtonProps) => {
	return minWidth[size]
}
