import {Input, makeStyles} from '@material-ui/core'
import {max} from 'lodash'
import {CSSProperties, useEffect, useMemo, useRef, useState} from 'react'
import {color} from 'src'
import {BreakPointOptions, DEFAULT_BREAK_POINTS, responsiveStyler} from '../text/ResponsiveHeader'
import {H1} from '../text/Text'

type ResponsiveInputProps = {
	value?: string | number
	preLabel?: string
	postLabel?: string
	id?: string
	style?: CSSProperties
	breakPoints?: BreakPointOptions[]
	onValueChange?: (value: string | number) => string
	inputType?: string
	defaultSize?: number
	inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined
}

const useStyles = makeStyles(theme => ({
	root: {
		color: theme?.palette?.text?.background || '#353b50',
		'&:not(.Mui-focused)&:before': {
			borderBottom: `2px solid ${theme?.palette?.text?.background || color.white}`,
		},
		'&.MuiInput-underline:hover:not(.Mui-disabled)&:before': {
			borderBottom: `2px solid ${theme?.palette?.text?.background || color.white}`,
		},
		'&.MuiInput-underline:before': {
			borderBottom: `2px solid ${theme?.palette?.background?.default || color.blue900}`,
		},
		'&.Mui-focused:after': {
			borderBottom: `2px solid ${theme?.palette?.background?.default || color.blue900}`,
		},
	},
}))

export const ResponsiveInput = ({
	id = 'reactive-input',
	value = 0,
	preLabel = '',
	postLabel = '',
	style = {},
	breakPoints = DEFAULT_BREAK_POINTS,
	onValueChange,
	inputType = 'text',
	defaultSize = 1,
	inputMode = 'numeric',
}: ResponsiveInputProps) => {
	const [currentValue, setValue] = useState(value)
	const classes = useStyles()

	const getSize = (valueString: string) => {
		return max([valueString?.length, defaultSize]) || 1
	}

	const responsiveStyle = useMemo(
		() => responsiveStyler(currentValue?.toString(), breakPoints),
		[currentValue, breakPoints],
	)
	const combinedStyle = {...style, ...responsiveStyle}

	const responsiveStyleInput = useMemo(() => {
		return {width: getSize(currentValue?.toString()) + 'ch'}
	}, [currentValue, breakPoints])
	const combinedStyleInput = {...combinedStyle, ...responsiveStyleInput}

	const onChange = (event: any) => {
		let value = event.target.value || 0
		value = onValueChange ? onValueChange(value) : value
		setValue(value)
		event.target.style.width = getSize(value.toString()) + 'ch'
	}

	useEffect(() => {
		setValue(value)
	}, [value])

	return (
		<H1 id={id} style={combinedStyle}>
			{preLabel}
			<Input
				inputProps={{style: combinedStyleInput}}
				title={`${id}-input`}
				id={`${id}-input`}
				className={classes.root}
				onChange={onChange}
				style={combinedStyle}
				type={inputType}
				inputMode={inputMode}
				value={currentValue}
				onPaste={e => {
					e.preventDefault()
					return false
				}}
				onCopy={e => {
					e.preventDefault()
					return false
				}}
				onCut={e => {
					e.preventDefault()
					return false
				}}
				onFocus={e => {
					const l = getSize(e.target.value)
					try {
						e.target.setSelectionRange(l, l)
					} catch (e) {
						console.error(e)
					}
					onChange(e)
				}}
				autoFocus
			/>
			{postLabel}
		</H1>
	)
}
