import React from 'react'

import {MinimalList} from 'components/List'
import {Icons, MaskedValue} from '@bakkt/components'
import {formatMaskedSSN, formatSSN} from 'utils/formatters'
import {useTranslation} from 'react-i18next'
import {EmbeddedWebState} from 'store'
import {NA} from 'components/Text'
import {useTaxIdentifier} from 'hooks'

export interface TaxpayerIdListProps {
	partyProfile: EmbeddedWebState['partyProfile']
	missingProfileFields: EmbeddedWebState['missingProfileFields']
}

export const TaxpayerIdList = ({partyProfile, missingProfileFields}: TaxpayerIdListProps) => {
	const {t} = useTranslation()
	const {getTaxIdentifier, loading, taxIdentifier, resetTaxIdentifier} = useTaxIdentifier()
	const taxIdentifierValue =
		taxIdentifier || missingProfileFields?.taxIdentifier || partyProfile?.Party?.taxIdentifier

	const onTaxIdentifierIconClick = (): boolean => {
		if (!taxIdentifier && !missingProfileFields?.taxIdentifier) getTaxIdentifier()
		else if (taxIdentifier) resetTaxIdentifier()
		return true
	}

	return (
		<MinimalList
			data={[
				{
					primaryText: t('inputLabel.SSN'),
					contentRight: taxIdentifierValue ? (
						<MaskedValue
							value={formatSSN(taxIdentifierValue)}
							maskedValue={formatMaskedSSN(taxIdentifierValue)}
							unMaskedIcon={Icons.Eyeball}
							maskedIcon={Icons.Eyeball}
							textStyle={{
								background: 'none',
								cursor: 'default',
								padding: 0,
								paddingRight: 8,
								margin: 0,
								fontWeight: 'normal',
								fontFamily: 'SF Pro Text',
								fontSize: '14px',
								userSelect: 'none',
							}}
							timeVisible={30000}
							onClick={onTaxIdentifierIconClick}
							loading={loading}
						/>
					) : (
						<NA />
					),
				},
				{
					primaryText: t('inputLabel.EIN'),
					contentRight: <NA />,
				},
			]}
		/>
	)
}
