import {useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import moment from 'moment'
import {EmbeddedWebState} from 'store'
import {showWarning} from 'components/Dialog'
import {RoutesName} from 'constants/routes'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'hooks'
import {showOnboardingErrorDialog} from 'components/ErrorDialog'
import {useStore} from 'store'

const {enrollPartnerParty} = embeddedPartnerApi

export const usePartnerPartyEnroll = (
	partyProfile: EmbeddedWebState['partyProfile'],
	missingProfileFields: EmbeddedWebState['missingProfileFields'],
	ew9Signature: string,
) => {
	const navigate = useNavigate()
	const {partner} = useStore()
	const {t} = useTranslation()

	const {request, response, loading, error} = ApiHook(() =>
		enrollPartnerParty({
			profileId: partyProfile?.profileId as any,
			missingFieldValue: {
				...missingProfileFields,
				partnerPartyRef: partyProfile?.Party?.partnerPartyRef || '',
				ew9Signature: ew9Signature,
				termsAndConditionsPublishTime: '' + moment().unix(),
				privacyPolicyPublishTime: '' + moment().unix(),
			},
		}),
	)

	useEffect(() => {
		if (error) showOnboardingErrorDialog(partner)
	}, [error])

	useEffect(() => {
		if (response) {
			navigate(`${RoutesName.onboarding.home}${RoutesName.onboarding.success}`, {ignore: true})
		}
	}, [response])

	return {
		loading,
		enrollPartnerParty: request,
	}
}
