import React from 'react'
import {useTranslation} from 'react-i18next'

import {H3, Large, Page, RoundCheckedIcon, PoweredBy} from '@bakkt/components'
import {BottomBox, Main} from 'components/Layout'
import Button from 'components/Button'
import {useStore} from 'store'

const CryptoAccountActivationSuccess = () => {
	const {t} = useTranslation()
	const {loadOnStartup, partner} = useStore()

	return (
		<Page titleHeader={t('crypto.accountActivate.activateCrypto')}>
			<Main style={{flexGrow: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
				<div style={{position: 'relative', margin: 0, display: 'flex', justifyContent: 'center'}}>
					<RoundCheckedIcon height='104px' width='104px' />
				</div>
				<div style={{textAlign: 'center', margin: '26px 0px 0px 0px', maxWidth: '100%', paddingBottom: '1em'}}>
					<H3 style={{marginBottom: '0px', marginTop: '0px'}}>
						{t('crypto.accountActivate.activationSuccess')}
					</H3>
					<Large style={{marginTop: '0.5em', fontFamily: 'BrownPro', marginBottom: '36px'}}>
						{t('crypto.accountActivate.canSellBuy', {partnerName: partner?.name})}
					</Large>
				</div>
			</Main>
			<BottomBox className='activationSuccessBottomBox' style={{paddingTop: '30px', marginBottom: '30px'}}>
				<Button aria-label='Activation Success' style={{marginBottom: '10px'}} onClick={() => loadOnStartup()}>
					{t('bntViewCrypto')}
				</Button>
				<PoweredBy text={t('poweredBy')} />
			</BottomBox>
		</Page>
	)
}
export default CryptoAccountActivationSuccess
