import {ApiAmountCurrencyEnum} from '@bakkt/api'
import {Icons} from '@bakkt/components'
import React from 'react'

export interface CurrencyDetail {
	shortName: string
	name: string
	icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	precision: number
}
const CURRENCY_DETAIL: {[key: string]: CurrencyDetail} = {
	[ApiAmountCurrencyEnum.USD]: {
		shortName: ApiAmountCurrencyEnum.USD,
		name: 'US Dollars',
		precision: 2,
	},
	[ApiAmountCurrencyEnum.BTC]: {
		shortName: ApiAmountCurrencyEnum.BTC,
		name: 'Bitcoin',
		icon: Icons.BitcoinAccount,
		precision: 8,
	},
	[ApiAmountCurrencyEnum.ETH]: {
		shortName: ApiAmountCurrencyEnum.ETH,
		name: 'Ethereum',
		icon: Icons.EthereumAccount,
		precision: 18,
	},
	[ApiAmountCurrencyEnum.LTC]: {
		shortName: ApiAmountCurrencyEnum.LTC,
		name: 'Litecoin',
		precision: 0,
	},
	[ApiAmountCurrencyEnum.LCP]: {
		shortName: ApiAmountCurrencyEnum.LCP,
		name: 'Litecoin Plus',
		precision: 0,
	},
	[ApiAmountCurrencyEnum.BCH]: {
		shortName: ApiAmountCurrencyEnum.BCH,
		name: 'Bitcoin Cash',
		precision: 0,
	},
	[ApiAmountCurrencyEnum.LMO]: {
		shortName: ApiAmountCurrencyEnum.LMO,
		name: 'Lumeneo',
		precision: 0,
	},
	[ApiAmountCurrencyEnum.LXX]: {
		shortName: ApiAmountCurrencyEnum.LXX,
		name: 'Luxxeum',
		precision: 0,
	},
	[ApiAmountCurrencyEnum.LKO]: {
		shortName: ApiAmountCurrencyEnum.LKO,
		name: 'LKO',
		precision: 0,
	},
	[ApiAmountCurrencyEnum.LWR]: {
		shortName: ApiAmountCurrencyEnum.LWR,
		name: 'LWR',
		precision: 0,
	},
}
export const getCryptoCurrencyDetails = (currency: string | undefined | null): CurrencyDetail | undefined | null => {
	if (currency === undefined || currency === null) {
		return currency
	}
	return CURRENCY_DETAIL[currency] || undefined
}
