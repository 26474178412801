import {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import {ApiEmbeddedFundingAccount} from '@bakkt/api'
import {VBox, HBox, Large, Light, Separator, Icons, color, toTitleCase, useTheme, CircleIcon} from '@bakkt/components'

import {formatFundingSourceInfo} from 'utils/formatters'
import {useStore} from 'store'
import './FundingAccount.scss'
import {Theme} from '@material-ui/core'

export type FundingSourceProps = {
	source: ApiEmbeddedFundingAccount
}

export const FundingAccount = ({source}: FundingSourceProps) => {
	const {setSelectedFundingAccount, setSelectedFundingAccountId, selectedFundingAccount} = useStore()
	const navigate = useNavigate()
	const theme = useTheme() as Theme

	const isSelected = useMemo(() => source?.externalAccountRef === selectedFundingAccount?.externalAccountRef, [])
	const sourceInfo = formatFundingSourceInfo(source)

	const onSourceSelected = () => {
		setSelectedFundingAccount(source)
		setSelectedFundingAccountId(source?.externalAccountRef)
		navigate(-1)
	}

	return (
		<div
			id={`funding-source-${source.externalAccountRef}`}
			style={{cursor: 'pointer'}}
			className='accountRow'
			onClick={onSourceSelected}
		>
			<VBox>
				<HBox>
					<VBox>
						<Large
							data-test-id={'funding-source-account-type'}
							id={`funding-source-account-type-${source.externalAccountRef}`}
							style={{marginBottom: 5, color: theme?.palette?.text?.background || color.blue900}}
						>
							{toTitleCase(source.accountType)}
						</Large>
						<Light
							data-test-id={'funding-source-account-info'}
							id={`funding-source-account-info-${source.externalAccountRef}`}
							style={{marginTop: 0, color: theme?.palette?.text?.background || color.grey700}}
						>
							{sourceInfo}
						</Light>
					</VBox>
					<div
						className={`check ${isSelected ? 'selected' : 'unselected'}`}
						style={{margin: 'auto 0 auto auto'}}
					>
						<CircleIcon
							id={`selected-funding-source-check-${source.externalAccountRef}`}
							icon={Icons.Check}
							iconColor={isSelected ? theme?.palette?.secondary?.contrastText || 'white' : 'transparent'}
							style={{
								background: isSelected
									? theme?.palette?.secondary?.main || color.blueKolibri
									: 'transparent',
								border: `1px ${theme?.palette?.text?.background} solid`,
							}}
						/>
					</div>
				</HBox>
				<Separator />
			</VBox>
		</div>
	)
}
