import {
	ResponsiveInput,
	ResponsiveHeader,
	VBox,
	HBox,
	Icons,
	ShadowContainer,
	color,
	useTheme,
	H2,
} from '@bakkt/components'
import {useStore} from 'store'

import {InputType, useSelectedCryptoAccountInfo} from 'hooks'
import {formatInputStringAsCurrency, formatCurrency} from 'utils/currencyFormatters'
import {Theme} from '@material-ui/core'

type FlipButtonProps = {
	id?: string
	onFlipPressed?: () => void
}

const FlipButton = ({id, onFlipPressed}: FlipButtonProps) => {
	const theme = useTheme() as Theme

	return onFlipPressed ? (
		<ShadowContainer
			id={id}
			onClick={onFlipPressed}
			style={{
				position: 'absolute',
				right: 0,
				top: 20,
				alignItems: 'center',
				justifyContent: 'center',
				width: 25,
				height: 25,
				marginLeft: 10,
				borderRadius: '50%',
				transform: 'rotate(90deg)',
				background: theme?.palette?.secondary?.main,
			}}
		>
			<Icons.Swap fill={theme?.palette?.text?.secondary || color.grey700} />
		</ShadowContainer>
	) : null
}

type CurrencyFlipperProps = {
	fiat: string | number
	crypto: string | number
	approximate?: boolean
	disabledInput?: boolean
	onChange?: (value: string) => void
	focusedCurrency?: InputType
	onFlipPressed?: () => void
}

export const CurrencyFlipper = ({
	fiat,
	crypto,
	approximate,
	onFlipPressed,
	disabledInput,
	onChange,
	focusedCurrency = InputType.Fiat,
}: CurrencyFlipperProps) => {
	const {fiatCurrencyPreference} = useStore()
	const {selectedCryptoCurrencyPrecision, selectedCryptoCurrency} = useSelectedCryptoAccountInfo()

	const fiatText = formatCurrency(
		Number(fiat),
		fiatCurrencyPreference,
		{
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		},
		true,
	).replace(/[^\d\.\-]/gm, '') // remove "$" etc label

	const cryptoText = formatCurrency(
		Number(crypto),
		selectedCryptoCurrency,
		{
			maximumFractionDigits: selectedCryptoCurrencyPrecision,
			// value changes on `flip` if zeros are dropped
			minimumFractionDigits: selectedCryptoCurrencyPrecision,
		},
		true,
	).replace(/[^\d\.\-]/gm, '') // remove "BTC" etc label

	const typeFromEnd = (val: string, precision: number) => {
		// so numbers are inserted from back
		const decimal = (val.split('.')[1]?.length || 0) - precision
		if (decimal !== 0) {
			return Number(val) * 10 ** decimal
		}
		return val
	}

	const currencyFormat = (value: string | number) => {
		const precision = focusedCurrency === InputType.Fiat ? 2 : selectedCryptoCurrencyPrecision || 8
		const cleanValue =
			typeof value === 'string' ? value.toString().replace(/[^\d]/gm, '') : Number(value || 0).toString()
		const number = Number(typeFromEnd(cleanValue, precision) || 0)
			.toFixed(precision)
			.toString()
		onChange && onChange(number.toString())
		return number
	}

	const primaryCurrencyId = focusedCurrency === InputType.Fiat ? fiatCurrencyPreference : selectedCryptoCurrency
	const secondaryCurrencyId = focusedCurrency === InputType.Fiat ? selectedCryptoCurrency : fiatCurrencyPreference

	const focusedCurrencyText =
		focusedCurrency === InputType.Fiat ? currencyFormat(fiatText) : currencyFormat(cryptoText)
	const focusedCurrencyPreLabel = focusedCurrency === InputType.Fiat ? '$' : selectedCryptoCurrency + ' '
	return (
		<HBox style={{position: 'relative', justifyContent: 'center'}}>
			<VBox style={{textAlign: 'center', marginBottom: 15}} verticalGap={15}>
				{disabledInput ? (
					<ResponsiveHeader
						style={{marginBottom: 10}}
						value={`${focusedCurrencyPreLabel}${focusedCurrencyText}`}
						id={`flipper-primary-currency-${primaryCurrencyId}`}
					/>
				) : (
					<ResponsiveInput
						id={`flipper-primary-currency-${primaryCurrencyId}`}
						value={focusedCurrencyText}
						preLabel={focusedCurrencyPreLabel}
						style={{marginBottom: 0}}
						onValueChange={currencyFormat}
					/>
				)}
				<H2
					id={`flipper-secondary-currency-${secondaryCurrencyId}`}
					style={{fontSize: '18px', fontWeight: 700, margin: 0}}
				>
					{focusedCurrency === InputType.Fiat ? selectedCryptoCurrency + ' ' : ''}
					{approximate ? '≈ ' : ''}
					{focusedCurrency === InputType.Fiat ? '' : '$'}
					{focusedCurrency === InputType.Fiat ? cryptoText : fiatText}
				</H2>
			</VBox>
			<FlipButton id={`toggle-to-${secondaryCurrencyId}`} onFlipPressed={onFlipPressed} />
		</HBox>
	)
}
