import {color} from '@bakkt/components'
import React, {CSSProperties} from 'react'
import {Link as RouterLink} from 'react-router-dom'

interface LinkProps {
	id?: string
	to: string
	ariaLabel?: string
	text: string
	style?: CSSProperties
}

export const Link = ({id, to, ariaLabel, text, style}: LinkProps) => (
	<RouterLink
		id={id}
		to={to}
		aria-label={ariaLabel}
		style={{
			marginTop: '28px',
			marginBottom: '5px',
			color: color.blue700,
			textDecoration: 'none',
			fontSize: '16px',
			...style,
		}}
	>
		{text}
	</RouterLink>
)
