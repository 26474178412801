import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {BakktDialogProps, BakktThemeProvider, CurrentTheme, Dialog} from '..'
import {Provider} from 'react-redux'

export default class Utils {
	static elementStates: {[key: string]: () => any} = {}
	static setState(props: any, getState: () => any) {
		const element = props.element || 'popupArea'
		this.elementStates[element] = getState
	}

	static showFuncDialog<P>(
		title: string,
		WrappedComponent: React.ComponentClass<any> | (() => JSX.Element) | any,
		props?: {hideSave?: boolean} & Partial<BakktDialogProps> & P,
		size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
		element?: string,
	): Promise<any> {
		return Utils.showCloseDialog(title, WrappedComponent, () => {}, props, size, element)
	}
	static showCloseDialog<P>(
		title: string,
		WrappedComponent: React.ComponentClass<any> | (() => JSX.Element) | any,
		onHide: (form: React.Component<P> | null) => void,
		props?: {hideSave?: boolean} & Partial<BakktDialogProps> & P,
		size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
		element?: string,
	): Promise<any> {
		return new Promise(resolve => {
			const App = () => {
				const [valid, setValid] = React.useState(true)
				const closeDialog = () => {
					resolve(null)
					onHide ? onHide(null) : Utils.hideDialog()
				}
				const formRef = React.useRef(null)
				const onValidation = (valid0: boolean) => {
					setValid(valid0)
				}
				const onSave = () => {
					const getState = this.elementStates[element || 'popupArea']
					resolve(getState && getState())
					onHide && onHide(formRef.current)
				}
				const showSave = !title.startsWith('View') && !(props && props.hideSave) && onHide
				return (
					<Dialog
						open={true}
						fullWidth={true}
						size={size || 'xs'}
						title={title}
						saveText={props?.saveText ? props.saveText : 'Save'}
						cancelText={props?.cancelText}
						skipText={props?.skipText}
						onSave={onSave}
						onCancel={closeDialog}
						hideSave={!showSave}
						hideCancel={props?.hideCancel}
						skip={props?.skip}
						disableSaveButton={!valid}
						icon={props?.icon}
						useCloseBtn={props?.fullScreen}
						onSkip={closeDialog}
						dialogStyle={props?.dialogStyle}
						saveButtonStyle={props?.saveButtonStyle}
						skipButtonStyle={props?.skipButtonStyle}
						cancelButtonStyle={props?.cancelButtonStyle}
						fullWidthButtons={props?.fullWidthButtons}
						buttonTextSize={props?.buttonTextSize}>
						<WrappedComponent element={element} onValidation={onValidation} {...props} ref={formRef} />
					</Dialog>
				)
			}
			ReactDOM.render(
				<Provider store={(window as any)['store']}>
					<BakktThemeProvider theme={CurrentTheme}>
						<App />
					</BakktThemeProvider>
				</Provider>,
				document.getElementById(element || 'popupArea'),
			)
		})
	}

	static showDialog(App: any) {
		ReactDOM.render(
			<React.StrictMode>
				<Provider store={(window as any)['store']}>
					<BakktThemeProvider theme={CurrentTheme}>
						<App />
					</BakktThemeProvider>
				</Provider>
			</React.StrictMode>,
			document.getElementById('popupArea'),
		)
	}

	static hideDialog(element?: string) {
		ReactDOM.unmountComponentAtNode((document as any).getElementById(element || 'popupArea'))
	}

	static showPopupMenu<P>(c: React.ReactElement<P>, e: MouseEvent) {
		function handle_click_outside() {
			Utils.hideDialog('popupMenu')
		}
		const App = class extends React.Component<any, any> {
			render() {
				return c
			}
			componentWillMount() {
				document.addEventListener('click', handle_click_outside)
			}
			componentWillUnmount() {
				document.removeEventListener('click', handle_click_outside)
			}
		}
		ReactDOM.render(
			<React.StrictMode>
				<Provider store={(window as any)['store']}>
					<div
						style={{
							position: 'fixed',
							top: e.clientY,
							left: e.clientX,
							zIndex: 10000000,
						}}>
						<App />
					</div>
				</Provider>
			</React.StrictMode>,
			document.getElementById('popupMenu'),
		)
	}

	static downloadFile(fileName: string, content: string, mimeType: string) {
		// for Excel, we need \ufeff at the start
		// http://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
		const blobObject = new Blob(['\ufeff', content], {
			type: mimeType,
		})
		const downloadLink = document.createElement('a')
		downloadLink.href = window.URL.createObjectURL(blobObject)
		downloadLink.download = fileName

		document.body.appendChild(downloadLink)
		downloadLink.click()
		document.body.removeChild(downloadLink)
	}

	static showPopup<P>(c: React.ReactElement<P>, element?: string): React.Component<P, React.ComponentState> {
		const App = class extends React.Component<any, any> {
			render() {
				return c
			}
		}
		return ReactDOM.render(
			<React.StrictMode>
				<Provider store={(window as any)['store']}>
					<App />
				</Provider>
			</React.StrictMode>,
			(element && document.getElementById(element)) || document.getElementById('popupArea'),
		) as any
	}

	static isListEqual(list1: any[], list2: any[]) {
		const leftList = list1 || []
		const rightList = list2 || []
		return leftList.length === rightList.length && leftList.every(el => rightList.includes(el))
	}
}
