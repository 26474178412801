import React, {HTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {color} from 'src/color/color'

export interface TagProps extends HTMLAttributes<HTMLDivElement> {
	text: number | string
	type?: 'danger' | 'warning'
	backgroundColor?: string
	textColor?: string
}

const GetBackground = (props: TagProps) => (props.type && color[props.type]) || color.danger

const DefaultTag = styled.div`
	display: inline-block;
	background: ${GetBackground as any};
`

const Text = styled.span`
	font-family: BrownPro;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 150%;
	color: ${color.black};
	margin: 0px 10px;
`

export const Tag: React.FC<TagProps> = (prop: TagProps) => (
	<DefaultTag {...prop} style={{backgroundColor: prop.backgroundColor}}>
		<Text style={{color: prop.textColor}}>{prop.text}</Text>
	</DefaultTag>
)
