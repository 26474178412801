import {useTranslation} from 'react-i18next'

export const PrivacyPolicy = () => {
	const {t} = useTranslation()
	return (
		<div className='fullScreenModalContent'>
			<div>
				<h1 className='title'> Bakkt Marketplace Privacy Notice </h1>
				<p>
					In this Privacy Notice (“<b>Notice</b>”), Bakkt (also referred to as “<b>Bakkt,</b>” “<b>we,</b>” “
					<b>us,</b>” or “<b>our</b>”) describes the types of personal information we may collect from you in
					connection with your interaction with us, including when you visit our websites or mobile
					application, or use our online products, services or content (the “Services”). These Services are
					available through{' '}
					<a target='_blank' href='https://www.bakkt.com/'>
						Bakkt.com
					</a>
					, the Bakkt mobile application, and social media pages.
				</p>
				<h3> PERSONAL INFORMATION WE COLLECT </h3>
				<p>
					<b>a. Personal information We Collect Directly from You</b>
				</p>
				<p>
					We collect personal information from you when you use our Services and interact with us (such as
					speaking with Bakkt representatives). The information we collect may include your name, state or
					country of residence, date of birth, address, phone number, social security number, account number,
					job title, company, email address, marketing and communications preferences, and other information
					you choose to provide us, including information provided when you register with us. In some
					circumstances, we may be required to collect information such as a government-issued ID, proof of
					address, photograph(s) (if you elect to provide them to us), and video (if you elect to provide it
					to us). We may also collect information relating to:
				</p>
				<ul>
					<li> Records and copies of your correspondence (including email addresses), if you contact us </li>
					<li>
						Your responses to surveys, such as those that we might ask you to complete for research
						purposes.
					</li>
					<li>
						Details of transactions you carry out using our Services. You may be required to provide
						financial information before using our Services.
					</li>
				</ul>
				<p>
					<b>b. Personal information We Collect Automatically</b>
				</p>
				<p>
					We collect information automatically when you interact with our Services. For example, we may use
					cookies, web beacons, and other similar technologies to collect information about you as you use our
					Services. Examples of this type of information include the dates and times of your use of the
					Services, your IP (Internet Protocol) address, your browser type, your operating system, device
					identifiers, and the webpages or content to or from which you navigate.
				</p>
				<p>
					Third parties may use cookies or similar technologies on our Services. For example, these
					technologies may be used to provide content and advertisements. We do not control third parties who
					may use these technologies to collect personal information about you, including information about
					your online activities over time and across different websites when you use our Services.
				</p>
				<p>
					<b> c. Personal Information We Collect From Third Parties</b>
				</p>
				<p>
					We also collect information about you from third parties, such as money laundering and fraud
					prevention information providers, marketing agencies, identity and creditworthiness verification
					services, and analytics and information providers. We may combine information we collect about you
					with information from third parties.
				</p>
				<hr />
			</div>
			{/** PERSONAL INFORMATION WE COLLECT **/}
			<div>
				<h3> 2. HOW WE USE YOUR INFORMATION </h3>
				<p>
					We use the information we gather to provide services to you and to respond to your inquiries. This
					includes to:
				</p>
				<ul>
					<li>
						Provide Services, including to:
						<ul className='sublist'>
							<li> - Register, create, and maintain your account; </li>
							<li> - Authenticate your identity and/or your access to an account; </li>
							<li> - Initiate, facilitate, process, and/or execute transactions; </li>
							<li> - Communicate with you regarding your account or any Services you use; </li>
							<li> - Perform creditworthiness, fraud prevention or other similar reviews; </li>
							<li> - Evaluate applications; or </li>
							<li> - Compare information for accuracy and verification purposes. </li>
						</ul>
					</li>
					<li> Manage our business and protect ourselves, you, other persons, and the Services. </li>
					<li> Provide a personalized experience and implement your preferences. </li>
					<li> Better understand our customers and how they use and interact with the Services. </li>
					<li>
						Market to you and provide personalized services, offers, and promotions on our websites and on
						third-party websites.
					</li>
					<li> Provide you with location-specific options, functionalities, and offers. </li>
					<li>
						Comply with our policies and obligations, including but not limited to, disclosures made in
						response to any requests from law enforcement authorities and/or regulators in accordance with
						any applicable law, rule, regulation, judicial or governmental order, regulatory authority of
						competent jurisdiction, discovery request, advice of counsel or similar legal process.{' '}
					</li>
					<li> Resolve disputes, collect fees, or troubleshoot problems</li>
					<li> Provide customer service to you or otherwise communicate with you. </li>
				</ul>
				<p>
					We may also process your personal information to fulfill the purposes for which you provide it, or
					with your consent.
				</p>
			</div>
			{/** HOW WE USE YOUR INFORMATION **/}
			<div>
				<h3> HOW WE SHARE INFORMATION </h3>
				<p>
					We may disclose the information we gather to our affiliates and to third parties. For example, we
					may share information with:
				</p>
				<ul>
					<li>
						Service providers and/or data processors: We may share personal information with third party
						service providers that perform services and functions at our direction and on our behalf. These
						third party service providers may, for example, provide you with services, verify your identity,
						assist in processing transactions, or provide customer support
					</li>
					<li>
						Other parties to transactions: We may share information with the other parties to your
						transactions, including retailers with whom we have partnered to provide the Services
					</li>
					<li>
						Financial Institutions: We may share information with financial institutions involved in
						carrying out the transactions in which you engage.
					</li>
					<li>
						Other third parties: We may share information with other third parties for our business purposes
						or as permitted or required by law, including:
						<ul className='sublist'>
							<li>
								- To comply with any legal, regulatory or contractual obligation, or with any legal or
								regulatory process (such as a valid court order or subpoena) or with the advice of
								counsel;
							</li>
							<li> - To market our or their products or services to you; </li>
							<li> - To establish, exercise, or defend legal claims or our policies; </li>
							<li>
								- If we believe disclosure is necessary or appropriate to protect the rights, property,
								or safety of ourselves, our customers, or others. This includes exchanging information
								with other companies and organizations for the purposes of fraud prevention and credit
								risk reduction; or
							</li>
							<li>
								- In connection with the purchase, sale, merger, consolidation or transfer of all or
								part of Bakkt’s business
							</li>
						</ul>
					</li>
				</ul>
				<p>We may also disclose data to fulfill the purposes for which you provide it, or with your consent </p>
			</div>
			{/** HOW WE SHARE INFORMATION **/}
			<div>
				<h3> CHANGES TO THIS PRIVACY NOTICE </h3>
				<p>
					We reserve the right to modify this Notice from time to time, and, we will notify you of changes to
					this Notice by revising this Notice and updating the Effective Date above. If you have an account
					with us, we will notify you of material changes to this notice by e-mail
				</p>
			</div>
			{/** CHANGES TO THIS PRIVACY **/}
			<div>
				<h3> YOUR CHOICES </h3>
				<p>
					If you wish to stop receiving marketing communications, you can opt out by emailing us at{' '}
					<a href='mailto:Privacy@Bakkt.com'>Privacy@Bakkt.com</a>. We may continue to send you non-marketing
					messages, such as messages relating to your transactions with us.
				</p>
				<p>
					As of the Effective Date listed above, there is no commonly accepted response for Do Not Track
					signals initiated by browsers. Therefore, we do not respond to such signals or to other mechanisms
					that provide the ability to exercise choice regarding the collection of personally identifiable
					information regarding your online activities over time and across third-party websites or online
					services.
				</p>
				<p>
					If you are a registered user of the Services, you may access your personal account information
					online and make changes by logging into your account.
				</p>
				<p>
					You can control the use of cookies at the individual browser level. If you reject cookies, you may
					still use our website, but your ability to use some features or areas of our website may be limited.
					You may control how your browser accepts cookies; please see our cookie policy for more information.
				</p>
			</div>
			{/** YOUR CHOICES **/}
			<div>
				<h3> SECURITY </h3>
				<p>
					Bakkt has implemented administrative, physical and technical safeguards designed to protect your
					personal information. Still, Bakkt cannot guarantee the security or confidentiality of information
					you transmit to us or receive from us.{' '}
				</p>
			</div>
			{/** SECURITY **/}
			<div>
				<h3> CALIFORNIA PRIVACY PRACTICES AND YOUR CALIFORNIA RIGHTS </h3>
				<p>
					If you are a California resident, see the Bakkt California Privacy Rights Addendum below for more
					information about our privacy practices and your rights under California law.
				</p>
			</div>
			{/** CALIFORNIA PRIVACY PRACTICES AND YOUR CALIFORNIA RIGHTS  **/}
			<div>
				<h3> CONTACT US </h3>
				<p>
					We take your privacy concerns seriously. If you have any questions about this Notice or if you
					believe that we have not complied with this Notice with respect to your personal information, you
					may write to <a href='mailto:Privacy@Bakkt.com'>Privacy@Bakkt.com</a>
				</p>
			</div>
			{/** CONTACT US **/}
			<div>
				<p>Bakkt California Privacy Rights Addendum</p>
				<p>
					If you are a California resident, the following provisions apply to our processing of information
					that identifies, relates to, describes, is capable of being associated with, or could reasonably be
					linked, directly or indirectly, with a particular consumer or household (“California Personal
					Information”). For such residents, the provisions of this California Privacy Rights Notice prevail
					over any conflicting provisions of this Notice.
				</p>
				<p>California Personal Information We Collect</p>
				<p>
					We may have collected the following categories of California Personal Information within the last 12
					months about you.
				</p>
				<p>
					(A) Identifiers such as a real name, alias, postal address, unique personal identifier, online
					identifier, IP address, email address, account name, social security number, driver’s license
					number, passport number, or other similar identifiers.
				</p>
				<p>
					(B) Personal information that identifies, relates to, describes, or is capable of being associated
					with, a particular individual, including, but not limited to their name, signature, physical
					characteristics or description, address, telephone number, passport number, driver’s license or
					state identification card number, bank account number, credit card number, debit card number, or any
					other financial information.
				</p>
				<p>
					(C) Characteristics of protected classifications under California or federal law (such as race,
					gender, age, disability status.)
				</p>
				<p>
					(D) Commercial information, including records of personal property, products or services purchased,
					obtained, or considered, or other purchasing or consuming histories or tendencies{' '}
				</p>
				<p>(E) Biometric information.</p>
				<p>
					(F) Internet or other electronic network activity information, including, but not limited to,
					browsing history, search history, and information regarding a consumer’s interaction with a website,
					application or advertisement.
				</p>
				<p>(G) Geolocation data.</p>
				<p>(H) Audio, electronic, visual, thermal, olfactory, or similar information.</p>
				<p>(I) Professional or employment-related information.</p>
				<p>(J) Education information that is not publicly available personally identifiable information.</p>
				<p>
					(K) Inferences drawn from any of the information identified in this subdivision to create a profile
					about you reflecting your preferences, characteristics, psychological trends, predispositions,
					behavior, attitudes, intelligence, abilities, and aptitudes.
				</p>
				<p>
					Within the next 12 months, within the meaning of the California Consumer Privacy Act, we will likely
					sell California Personal Information identified in the above categories (A)-(K).
				</p>
				<p>
					Within the last 12 months, we have disclosed California Personal Information identified in the above
					categories (A)-(K) for our business purposes. To learn more about the categories of third parties
					with whom we share such information, please see HOW WE SHARE DATA section of this Notice.
				</p>
				<p>
					For each of these categories, we obtain California Personal Information from a variety of sources.
					These sources include: yourself with respect to both online and offline interactions you may have
					with us or our service providers; other entities with whom you transact business; others with whom
					you maintain relationships who may deal with us on your behalf; the devices you use to access our
					websites, mobile applications, and online services; credit bureaus; identity verification and fraud
					prevention services; marketing and analytics providers; public databases; social media platforms;
					and others consistent with this Notice. For more information, please see PERSONAL INFORMATION WE
					COLLECT section of this Notice.
				</p>
				<p>
					We do not disclose personal information of individuals we know to be under the age of 16 to business
					or third parties for monetary or other valuable consideration as a “sale” under California law,
					without affirmative authorization.
				</p>
			</div>
			{/**Bakkt California Privacy Rights Addendum**/}
			<div>
				<h3>Use of California Personal Information </h3>
				<p>
					For each of the above categories, we use the California Personal Information we collect for the
					business purposes disclosed in HOW WE USE YOUR INFORMATION section of this Notice. Please note that
					the business purposes for which we use your information may include:{' '}
				</p>
				<ul>
					<li>
						Audits and reporting relating to particular transactions and interactions, including online
						interactions, you may have with us or others on our behalf;
					</li>
					<li>
						Detecting and protecting against security incidents and malicious, deceptive, fraudulent or
						illegal activity, and prosecuting the same;
					</li>
					<li>Debugging to identify and repair errors in our systems;</li>
					<li>Short-term, transient use including contextual customization of ads;</li>
					<li>
						Providing services on our behalf or on behalf of another, including maintaining or servicing
						accounts, providing customer service, fulfilling transactions, verifying identity information,
						processing payments, and other services;
					</li>
					<li>Conducting internal research to develop and demonstrate technology; and</li>
					<li>
						Conducting activity to verify, enhance, and maintain the quality or safety of services or
						devices which we may own, control, or provide.
					</li>
				</ul>
				<p>
					We may also use the information we collect for our own or our service providers’ other operational
					purposes, purposes for which we provide you additional notice, or for purposes compatible with the
					context in which the California Personal Information was collected.
				</p>
			</div>
			{/** Use of California Personal Information  **/}
			<div>
				<h3> Your California Rights </h3>
				<p>
					If you are a California resident, you have certain rights related to your California Personal
					Information, including:
				</p>
				<ul>
					<li>
						The right to request that we disclose to you
						<ul className='sublist'>
							<li> - Specific species of personal information we have collected; </li>
							<li> - Categories of California Personal Information we have collected about you;</li>
							<li>
								- Categories of sources from which the California Personal Information is collected;
							</li>
							<li>
								- Our business or commercial purpose for collecting or selling California Personal
								Information; and
							</li>
							<li>- Categories of third parties with whom we share California Personal Information.</li>
						</ul>
					</li>
					<li>
						The right to request that we delete California Personal Information about you which we have
						collected from you.
					</li>
					<li>
						To the extent that we sell your California Personal Information within the meaning of the
						California Consumer Privacy Act or disclose such information for a business purpose, you may
						request that we disclose to you the categories of California Personal Information we have
						collected about you as well as the categories of California Personal Information we have sold,
						the categories of third parties to whom the California Personal Information was sold, by
						category or categories of personal information for each third party to whom the California
						personal information was sold; and the categories of California Personal Information about you
						that we disclosed for a business purpose.
					</li>
					<li>
						The right to opt-out. We may sell your information within the meaning of the California Consumer
						Privacy Act. You have the right to opt out of such sales here. To submit an opt-out request,
						please email us at <a href='mailto:Privacy@Bakkt.com'>Privacy@Bakkt.com</a>
					</li>
				</ul>
			</div>
			{/** Your California Rights **/}
			<div>
				<p>You may request to exercise these rights by:</p>
				<ul>
					<li>Logging in to your account (if you have an account) and submitting a request;</li>
					<li>
						Emailing us at <a href='mailto:Privacy@Bakkt.com'>Privacy@Bakkt.com</a>;
					</li>
				</ul>
				<p>
					As required under applicable law, please note that we may take steps to verify your identity before
					granting you access to information or acting on your request to exercise your rights. We may require
					you to provide additional personal information as necessary to verify your identity in response to
					exercising requests of the above type. We may limit our response to your exercise of the above
					rights as permitted under applicable law. Subject to applicable law, we may not discriminate against
					you because of your exercise of any of the above rights, or any other rights under the California
					Consumer Privacy Act, including by
				</p>
				<ul>
					<li> Denying you goods or services;</li>
					<li>
						Charging different prices or rates for goods or services, including through the use of discounts
						or other benefits or by imposing penalties;
					</li>
					<li>Providing you a different level or quality of goods or services; or</li>
					<li>
						Suggesting that you will receive a different price or rate for goods or services or a different
						level or quality of goods or services. Under California law, you may designate an authorized
						agent to make a request on your behalf. You may make such a designation by providing the agent
						with written permission to act on your behalf. As permitted by law, we may require you to verify
						your own identity in response to a request even if you choose to use an agent. We may deny a
						request by an agent if they fail to submit proof that they act on your behalf.{' '}
					</li>
				</ul>
			</div>
			{/** You may request to exercise these rights by: **/}
			<div>
				<h3>Accessibility Statement</h3>
				<p>
					If you use assistive technology and the format of this Privacy Notice interferes with your ability
					to access information, please contact us at <a href='mailto:Privacy@Bakkt.com'>Privacy@Bakkt.com</a>
					. To enable us to respond in a manner most helpful to you, please indicate the preferred format in
					which to receive the material and provide your contact information.
				</p>
				<p>
					You may contact us with questions and concerns about our privacy policies or practices by contacting
					us at <a href='mailto:Privacy@Bakkt.com'>Privacy@Bakkt.com</a>.
				</p>
			</div>
			{/** Accessibility Statement **/}
			<div>
				<h3> Do Not Sell My Personal Information </h3>
				<p>
					If you are a California resident, you have the right to opt out of our sales of certain information
					relating to individuals and households subject to the California Consumer Privacy Act. To exercise
					this opt-out right, please login to your account (if you have an account) or, if you do not have an
					account, please submit the following information to us at{' '}
					<a href='mailto:Privacy@Bakkt.com'>Privacy@Bakkt.com</a>
				</p>
				<p>our email and mobile phone number.</p>
				<p>This California-specific addendum was last updated: January 1, 2020.</p>
				<p>
					LEGAL02/39339728v10
					<br />
					LEGAL02/39339728v12
					<br />
					LEGAL02/39559511v2
				</p>
			</div>
			{/** Do Not Sell My Personal Information **/}
		</div>
	)
}
