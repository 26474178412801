import React from 'react'
import {useTheme} from '@material-ui/core'
import MUICheckbox, {CheckboxProps as MUICheckboxProps} from '@material-ui/core/Checkbox'
import {FormControlLabel} from 'src/components/formControlLabel'
import {InactiveCheckboxUnchecked, InactiveCheckboxChecked, UncheckedCheckbox} from 'src/components/icons'
import {makeStyles} from '@material-ui/core/styles'

export interface CheckboxProps extends MUICheckboxProps {
	label?: React.ReactNode
	disabled?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
	checked: boolean
	testId?: string
}

const CheckedCheckbox = (props: React.SVGProps<SVGSVGElement>) => {
	const theme = useTheme()

	return (
		<svg
			width={16}
			height={16}
			fill={theme?.palette?.secondary?.main || '#3FD899'}
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<rect width={16} height={16} rx={4} />
			<path
				d='M12.5 4.25c-2.391 1.978-4.98 4.483-6.007 7.5H6.2C5.75 10.386 4.625 9.364 3.5 8.838'
				stroke={theme?.palette?.secondary?.contrastText || '#fff'}
				strokeWidth={1.5}
			/>
		</svg>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:focus-within': {
			'.MuiIconButton-label': {
				// TODO: get Theme color for focus highlight
				boxShadow: '0 0 0 3pt #71b3ff !important',
			},
		},
	},
}))

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
	const {label, disabled, onChange, checked, testId = 'default-check-box', ...rest} = props
	const classes = useStyles()
	const dataTestId = disabled ? `${testId}-disabled` : checked ? `${testId}-checked` : `${testId}-unchecked`

	return (
		<FormControlLabel
			control={
				<MUICheckbox
					classes={{root: classes.root}}
					data-testid={dataTestId}
					icon={disabled ? <InactiveCheckboxUnchecked /> : <UncheckedCheckbox />}
					checkedIcon={disabled ? <InactiveCheckboxChecked /> : <CheckedCheckbox />}
					disableRipple={true}
					disabled={disabled}
					onChange={onChange}
					checked={checked}
					{...rest}
				/>
			}
			label={label}
			disabled={disabled}
		/>
	)
}
