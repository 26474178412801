import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Page, color, PoweredBy} from '@bakkt/components'
import {ApiEmbeddedPartnerTransactionCryptoCurrencyTransactTypeEnum as TransactionType} from '@bakkt/api'
import {useNavigate, useSelectedCryptoAccountInfo, useCryptoCurrencyExecutePrices, InputType} from 'hooks'
import {BottomBox, Main} from 'components/Layout'
import Button from 'components/Button'
import {CurrencyFlipper} from 'components/CurrencyFlipper'
import {FundingAccountRow, CryptoAccountRow} from 'components/fundingRows'
import {useStore} from 'store'
import {showWarning} from 'components/Dialog'
import {RoutesName} from 'constants/routes'

type Props = {
	transactionType: TransactionType
}

export const CryptoTransactionInput = ({transactionType}: Props) => {
	const {fundingAccounts, selectedFundingAccount, selectedCryptoAccount, setCryptoTransactionConfirm} = useStore()
	const {selectedCryptoCurrencyName, selectedCryptoCurrencyPrecision} = useSelectedCryptoAccountInfo()

	const [inputType, setInputType] = useState<InputType>(InputType.Fiat)
	const {fiatAmount, setFiatAmount, cryptoAmount, setCryptoAmount, approximate} = useCryptoCurrencyExecutePrices({
		transactionType,
		inputType,
	})

	const {t} = useTranslation()
	const navigate = useNavigate()

	const canSelectFundingAccount = fundingAccounts && fundingAccounts.length > 1

	const onChangeValue = (value: string) => {
		inputType === InputType.Fiat ? setFiatAmount(value) : setCryptoAmount(value)
	}

	const isAmountAboveMinimum = () => {
		const isFiatAboveMinimum = fiatAmount && fiatAmount >= 0.01
		const isCryptoAboveMinimum = cryptoAmount && cryptoAmount >= 1 / 10 ** (selectedCryptoCurrencyPrecision || 2)
		return isFiatAboveMinimum && isCryptoAboveMinimum
	}

	const isAmountLessThanMaximum = () => {
		switch (transactionType) {
			case TransactionType.BUY:
				const maxAmount = selectedFundingAccount?.availableBalance?.amount
				return fiatAmount && maxAmount && fiatAmount <= maxAmount
			case TransactionType.SELL:
				return cryptoAmount && cryptoAmount <= (selectedCryptoAccount?.accountBalance?.amount || 0)
		}
	}

	const onButtonClicked = () => {
		if (!isAmountLessThanMaximum()) {
			showWarning({
				title: t('label.insufficientBalance'),
				message: t('crypto.buy.insufficientBalance'),
				skipText: t('btnOkGotIt'),
				skipButtonStyle: {color: color.blue700},
			})
		} else {
			navigate(RoutesName.crypto.confirm, {ignore: true})
		}
	}

	const navigateToFunding = () => {
		navigate('funding', {ignore: true})
	}

	return (
		<Page
			withBackIcon
			titleHeader={
				transactionType === TransactionType.SELL
					? `Sell ${selectedCryptoCurrencyName}`
					: `Buy ${selectedCryptoCurrencyName}`
			}
		>
			<Main>
				<CurrencyFlipper
					fiat={fiatAmount}
					crypto={cryptoAmount}
					approximate={approximate}
					onChange={onChangeValue}
					focusedCurrency={inputType}
					onFlipPressed={() => setInputType(inputType === InputType.Fiat ? InputType.Crypto : InputType.Fiat)}
				/>
				{transactionType === TransactionType.SELL && <CryptoAccountRow />}
				<FundingAccountRow
					label={transactionType === TransactionType.SELL ? t('label.destination') : t('label.fundingSource')}
					onClick={canSelectFundingAccount ? navigateToFunding : undefined}
					style={{marginBottom: '50px'}}
				/>
			</Main>
			<BottomBox style={{marginTop: '50px', marginBottom: 'auto'}}>
				<Button
					disabled={!selectedFundingAccount || approximate || !isAmountAboveMinimum()}
					onClick={onButtonClicked}
					id='buy-sell-continue-button'
				>
					{t('bntContinue')}
				</Button>
				<PoweredBy text={t('poweredBy')} />
			</BottomBox>
		</Page>
	)
}
