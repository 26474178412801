import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Main, BottomBox} from 'components/Layout'
import {Page, Large, Medium, H2, H3, Input, NumberedList} from '@bakkt/components'
import Button from 'components/Button'
import {useStore} from 'store'
import {usePartnerPartyEnroll} from 'hooks'
import {TaxpayerIdList} from 'components/onboarding/taxpayer/TaxpayerIdList'
import {TaxpayerInformationList} from 'components/onboarding/taxpayer/TaxpayerInformationList'
import {nameFormatter} from 'utils/formatters'
import ExitOnboardingButton from 'components/ExitOnboardingButton'

const TaxpayerCertification = () => {
	const {t} = useTranslation()
	const {partyProfile, missingProfileFields} = useStore()
	const [isValidSignature, setIsValidSignature] = useState<boolean>(false)
	const [ew9Signature, setEw9Signature] = useState<string>('')
	const {enrollPartnerParty, loading} = usePartnerPartyEnroll(partyProfile, missingProfileFields, ew9Signature)
	const [disableButton, setDisableButton] = useState(false)

	const validateSignature = (text: string | number): boolean => {
		setDisableButton(false)

		const concatedName = nameFormatter(
			partyProfile?.Party?.firstName || missingProfileFields?.firstName,
			partyProfile?.Party?.lastName || missingProfileFields?.lastName,
		)

		const isValid = text.toString().toLowerCase().trim() === concatedName.toLowerCase().trim()
		setIsValidSignature(isValid)
		return isValid
	}

	function handleClick() {
		enrollPartnerParty()
		setDisableButton(true)
	}

	return (
		<Page titleHeader={t('crypto.accountActivate.activateCrypto')} loading={loading}>
			<Main>
				<H2 data-testid={'section-title'}>{t('crypto.accountActivate.payerCertificationTitle')}</H2>
				<Large data-testid={'section-description-1'}>
					{t('crypto.accountActivate.payerCertificationDes1')}
				</Large>
				<Large data-testid={'section-description-2'}>
					{t('crypto.accountActivate.payerCertificationDes2')}
				</Large>

				<H3 underline>{t('crypto.accountActivate.payerInfo')}</H3>
				<TaxpayerInformationList partyProfile={partyProfile} missingProfileFields={missingProfileFields} />

				<H3 underline>{t('crypto.accountActivate.payerIdentification')}</H3>
				<TaxpayerIdList partyProfile={partyProfile} missingProfileFields={missingProfileFields} />

				<H3 underline>{t('crypto.accountActivate.certification')}</H3>
				<Medium>{t('crypto.accountActivate.underPenalties')}</Medium>
				<NumberedList
					data={[
						t('crypto.accountActivate.numberShowCorrect'),
						t('crypto.accountActivate.notSubject'),
						t('crypto.accountActivate.amUs'),
					]}
				/>

				<H3 underline>{t('crypto.accountActivate.signAndCertify')}</H3>
				<Medium>{t('crypto.accountActivate.internalRevenue')}</Medium>
				<Input
					id='TaxpayerCertification_DigitalSignature'
					label={t('inputLabel.digitalSignature')}
					name='digital-signature'
					type='text'
					helperText={t('label.digitalSignatureHelper')}
					fullWidth
					onChange={(_, value) => {
						setEw9Signature(value)
						setDisableButton(true)
					}}
					validate={validateSignature}
					validateOnInputDelay={2000}
					containerstyle={{marginBottom: '30px'}}
				/>
			</Main>
			<BottomBox>
				<Button
					id='EnrollButton'
					aria-label='Sign Up'
					onClick={() => handleClick()}
					disabled={!isValidSignature || disableButton}
					style={{fontSize: '16px'}}
				>
					{t('crypto.accountActivate.certifyAndComplete')}
				</Button>
				<ExitOnboardingButton style={{marginBottom: '5px'}} />
			</BottomBox>
		</Page>
	)
}
export default TaxpayerCertification
