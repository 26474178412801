import {useState, useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {ApiEmbeddedCryptoPriceResponse} from '@bakkt/api'
import {useStore} from 'store'

const INTERVAL_TIME = 30000 // milliseconds

const {getCryptoCurrencyPrices} = embeddedPartnerApi

export const useCryptoCurrencyPrices = () => {
	const {selectedCryptoAccount, fiatCurrencyPreference} = useStore()
	const [cryptoCurrencyPrice, setCryptoCurrencyPrice] = useState<ApiEmbeddedCryptoPriceResponse | null>(null)

	const {request, response} = ApiHook(() =>
		getCryptoCurrencyPrices(
			selectedCryptoAccount?.accountBalance?.currency || 'BTC',
			fiatCurrencyPreference || 'USD',
		),
	)

	useEffect(() => {
		setCryptoCurrencyPrice(response)
	}, [response])

	useEffect(() => {
		request()
		const interval = setInterval(() => {
			request()
		}, INTERVAL_TIME)
		return () => {
			clearInterval(interval)
		}
	}, [selectedCryptoAccount])

	return {
		cryptoCurrencyPrice,
		request,
	}
}
