import {ThemeOptions} from '@material-ui/core'
import {BrownPro} from 'src'

export const portalTheme: ThemeOptions = {
	name: 'Portal Theme',
	palette: {
		type: 'light',
		background: {
			default: '#FFFFFF',
		},
		primary: {
			main: '#0F2B29',
			contrastText: '#3FD899',
		},
		secondary: {
			main: '#0F2B29',
			contrastText: '#3FD899',
		},
		tertiary: {
			main: '#3FD899',
			contrastText: '#0F2B29',
		},
		text: {
			primary: '#100722',
			secondary: '#40394E',
		},
	},
	typography: {
		fontFamily: BrownPro,
	},
}

export const embeddedTheme: ThemeOptions = {
	name: 'Embedded Theme',
	palette: {
		type: 'light',
		background: {
			default: 'white',
		},
		primary: {
			main: '#004879',
			contrastText: 'white',
		},
		secondary: {
			main: '#004879',
			contrastText: 'white',
		},
		tertiary: {
			main: '#004879',
			contrastText: 'white',
		},
		text: {
			primary: '#100722',
			secondary: '#40394E',
		},
	},
	typography: {
		fontFamily: BrownPro,
	},
}

export const defaultLightTheme: ThemeOptions = {
	name: 'Light - Default',
	palette: {
		type: 'light',
		background: {
			default: '#ffffff',
		},
		primary: {
			main: '#ffffff',
			contrastText: '#100722',
		},
		secondary: {
			main: '#0F2B29',
			contrastText: '#3FD899',
		},
		tertiary: {
			main: '#ABF4C7',
			contrastText: '#100722',
		},
		text: {
			background: '#000000',
			primary: '#100722',
			secondary: '#3FD899',
			tertiary: '#100722',
			newLink: '#0567DE',
			visitedLink: '#551A8B',
		},
	},
	typography: {
		fontFamily: BrownPro,
	},
}

export const defaultDarkTheme: ThemeOptions = {
	name: 'Dark - Default',
	palette: {
		type: 'dark',
		background: {
			default: '#100722',
		},
		primary: {
			main: '#100722',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#ABF4C7',
			contrastText: '#100722',
		},
		tertiary: {
			main: '#ABF4C7',
			contrastText: '#100722',
		},
		text: {
			background: '#FFFFFF',
			primary: '#FFFFFF',
			secondary: '#100722',
			tertiary: '#100722',
			newLink: '#0567DE',
			visitedLink: '#551A8B',
		},
	},
	typography: {
		fontFamily: BrownPro,
	},
}

export const sampleFont: ThemeOptions = {
	name: 'Sample Font',
	palette: {
		type: 'light',
		background: {
			default: '#FFFFFF',
		},
		primary: {
			main: '#0F2B29',
			contrastText: '#3FD899',
		},
		secondary: {
			main: '#0F2B29',
			contrastText: '#3FD899',
		},
		tertiary: {
			main: '#3FD899',
			contrastText: '#0F2B29',
		},
		text: {
			primary: '#100722',
			secondary: '#40394E',
		},
	},
	typography: {
		fontFamily: 'serif',
	},
}
