import {useTranslation} from 'react-i18next'
import {Navigate} from 'react-router-dom'
import {ApiEmbeddedPartnerTransactionCryptoCurrencyTransactTypeEnum as TransactionType} from '@bakkt/api'
import {Main, BottomBox} from 'components/Layout'
import {Page, VBox, Large, H4, RoundCheckedIcon} from '@bakkt/components'
import Button from 'components/Button'
import {RoutesName} from 'constants/routes'
import {useStore} from 'store'
import {formatCrypto, formatInputStringAsCurrency} from 'utils/currencyFormatters'
import {useSelectedCryptoAccountInfo} from 'hooks/useSelectedCryptoAccountInfo'
import {useNavigate} from 'hooks'

type Props = {
	transactionType: TransactionType
}
export const CryptoTransactionSuccess = ({transactionType}: Props) => {
	const {t} = useTranslation()
	const {selectedCryptoCurrencyName} = useSelectedCryptoAccountInfo()
	const navigate = useNavigate()
	const {cryptoTransaction, resetToHome} = useStore()
	const cryptoQuantity = cryptoTransaction?.quantity

	if (!cryptoQuantity) {
		return (
			<Navigate
				to={`${
					transactionType === TransactionType.BUY ? `/${RoutesName.crypto.buy}` : `/${RoutesName.crypto.sell}`
				}`}
				replace
			/>
		)
	}
	return (
		<Page
			titleHeader={
				transactionType === TransactionType.SELL
					? `Sell ${selectedCryptoCurrencyName}`
					: `Buy ${selectedCryptoCurrencyName}`
			}
		>
			<Main center style={{marginBottom: '100px'}}>
				<div style={{display: 'flex', justifyContent: 'center', marginBottom: '24px'}}>
					<RoundCheckedIcon height='104px' width='104px' />
				</div>

				<H4 data-testid={'section-title'} style={{margin: '0 0'}}>
					{t('crypto.transaction.success')}
				</H4>
				<VBox style={{marginTop: '0.6rem'}}>
					<Large style={{margin: 0}} data-testid={'section-description'}>
						{transactionType === TransactionType.BUY ? t('crypto.buy.purchase') : t('crypto.sell.sold')}
					</Large>

					<Large style={{margin: 0}}>
						<b data-testid={'transaction-crypto-amount'}>
							{formatCrypto(cryptoQuantity?.amount || 0, cryptoQuantity?.currency)}
						</b>
						{' for '}
						<b data-testid={'transaction-usd-value'}>{`${
							cryptoTransaction?.price?.amount &&
							cryptoTransaction?.price?.currency &&
							formatInputStringAsCurrency(
								(cryptoTransaction?.price?.amount || 0).toString(),
								cryptoTransaction?.price?.currency,
							)
						}`}</b>
					</Large>
				</VBox>
			</Main>
			<BottomBox className={'bottomBox'}>
				<Button
					aria-label='Click to go to Overview'
					className={'viewCryptoAccountsBtn'}
					onClick={() => {
						resetToHome()
						navigate(`/${RoutesName.crypto.overview}`, {replace: true, ignore: true})
					}}
				>
					{`View My ${selectedCryptoCurrencyName}`}
				</Button>
			</BottomBox>
		</Page>
	)
}
