import React, {HTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {color} from 'src/color/color'

export interface ValueProps extends HTMLAttributes<HTMLSpanElement> {}

const DefaultValue = styled.span`
	font-family: BrownPro;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 150%;
	color: ${color.black};
	flex: none;
	order: 0;
	align-self: center;
	flex-grow: 0;
	margin: 10px 0px;
`

export const Value: React.FC<ValueProps> = ({children, ...props}) => <DefaultValue {...props}>{children}</DefaultValue>
