import {useState} from 'react'
import * as api from '@bakkt/api'
import axios from 'axios'
import {AxiosPromise} from 'axios'
import {ApiError} from '@bakkt/api'
import {setSessionStorageKey, getSessionStorageKey} from 'utils/sessionStorage'
import {SessionStorageKeys} from 'utils/sessionStorageProperties'
import {getEnv} from 'utils/config'
import {DeviceUUID} from 'device-uuid'
import {useStore} from 'store'

const global: any = window
const uuid = new DeviceUUID()

const params = new URLSearchParams(window.document.location.search)
setSessionStorageKey(
	SessionStorageKeys.PARTNER,
	params.get('partner') || getSessionStorageKey(SessionStorageKeys.PARTNER) || '',
)

const SERVER_URL = getEnv('SERVER_URL')

const baseUrl = SERVER_URL

// send session cookie
axios.defaults.withCredentials = true

axios.interceptors.request.use(req => {
	const fakeError = getSessionStorageKey(SessionStorageKeys.FAKE_ERROR)
	const fakeErrorCode = getSessionStorageKey(SessionStorageKeys.FAKE_ERROR_CODE)

	if (fakeError) req.headers['X-Fake-Error'] = fakeError
	if (fakeErrorCode) req.headers['X-Fake-Error-Code'] = fakeErrorCode

	const deriveDeviceType = () => {
		const {isAndroid, isiPad, isiPhone} = uuid.parse()

		if (isiPhone) return 'iphone'
		else if (isiPad) return 'ipad'
		else if (isAndroid) return 'android'
		else return 'browser'
	}

	req.headers['X-User-Agent'] = uuid.parse().source
	req.headers['X-Device-Type'] = deriveDeviceType()
	req.headers['X-Device-ID'] = uuid.get()

	return req
})

axios.interceptors.response.use(
	res => {
		if (res?.data?.error?.code === 'PARTY_SUSPENDED') {
			const {setIsSuspended} = useStore.getState()
			setIsSuspended(true)
		}
		return res
	},
	error => {
		if (error.response && error.response.status === 401) {
			redirectToLogin()
		}
		return Promise.reject({...error})
	},
)

const configuration = new api.Configuration()

export const embeddedPartnerApi = api.EmbeddedPartnerAPIApiFactory(configuration, baseUrl)

export const redirectToLogin = () => {
	const partner = getSessionStorageKey(SessionStorageKeys.PARTNER) || 'finastra-sandbox'
	global.location = `${SERVER_URL}/partnerauth/${partner}/login-redirect`
}

export const logout = () => {
	try {
		const partner = getSessionStorageKey(SessionStorageKeys.PARTNER) || 'finastra-sandbox'
		axios.post(`${SERVER_URL}/partnerauth/${partner}/logout`)
	} catch (error) {
		console.error(error)
	}
}
const {getPartnerPartyLinkInfo} = embeddedPartnerApi

export const loadOnStartup = async () => {
	try {
		const partyInfo = await getPartnerPartyLinkInfo()
		setInterval(() => {
			const partner = getSessionStorageKey(SessionStorageKeys.PARTNER) || 'finastra-sandbox'
			axios.get(`${SERVER_URL}/partnerauth/${partner}/refresh-token`)
		}, 60000)
		return partyInfo.data?.payload
	} catch (error) {
		console.error(error)
		return null
	}
}

type ApiHook<Response> = {
	loading: Boolean
	request: (...input: (string | object)[]) => void
	response: Response
	error: object | string | null
}

type Api<T> = () => AxiosPromise<{error?: ApiError; message?: string; payload?: T; success?: boolean}>

export function ApiHook<T>(api: Api<T>) {
	const [loading, setLoading] = useState(false)
	const [response, setResponse] = useState(null)
	const [error, setError] = useState<any>(null)
	const request = async () => {
		if (loading) return {}
		setLoading(true)
		try {
			const response = await api()
			if (response.data.success) {
				setResponse((response?.data?.payload as any) || true)
			} else {
				setError(response?.data?.error)
			}
		} catch (err) {
			setError(err)
		} finally {
			setLoading(false)
		}
	}
	const responseWithType: T | null = response as any

	return {
		loading,
		request,
		response: responseWithType,
		error,
	}
}

type apiCall<Response> = {
	response: Response
	error: object | string | null
}

export async function apiCall<T>(api: Api<T>): Promise<{response?: T; error?: ApiError}> {
	try {
		const apiResponse = await api()
		if (apiResponse.data.success) {
			return {response: apiResponse?.data?.payload}
		} else {
			return {error: apiResponse.data.error}
		}
	} catch (err) {
		console.error(err)
		return {error: {code: 'UNKNOWN', message: 'Unknown Error'}}
	}
}
