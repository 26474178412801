import React, {Children, ReactNode} from 'react'

import {H3, Light} from '../text/Text'
import {ShadowContainer} from '../shadowContainer/ShadowContainer'
import {Separator} from '../separator'
import {useTheme} from '@material-ui/core'

type FormContainerProps = {
	header: string
	text?: string
	children: ReactNode
}

export const FormContainer = ({header, text, children}: FormContainerProps) => {
	const childCount = Children.toArray(children).length
	const theme = useTheme()

	return (
		<>
			{childCount ? (
				<>
					<H3>{header}</H3>
					{text && <Light>{text}</Light>}
					<ShadowContainer
						style={{
							display: 'flex',
							alignItems: 'stretch',
							marginBottom: 40,
							backgroundColor: theme?.palette?.background?.default || 'white',
						}}>
						{Children.map(
							children,
							(child, i) =>
								child && (
									<>
										{child}
										{i < childCount - 1 && <Separator />}
									</>
								),
						)}
					</ShadowContainer>
				</>
			) : null}
		</>
	)
}
