import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Page, H3} from '@bakkt/components'

import {FundingAccount} from 'components/FundingAccount'
import {Main} from 'components/Layout'
import {ApiHook, embeddedPartnerApi} from 'api'
import {useStore, TransactTypeEnum, TransactType} from 'store'
import {useSelectedCryptoAccountInfo} from 'hooks/useSelectedCryptoAccountInfo'
import {showTransactionErrorDialog} from 'components/ErrorDialog'

type Props = {
	transactionType: TransactTypeEnum
}

export const SelectFundingAccount = ({transactionType}: Props) => {
	const {t} = useTranslation()
	const {fiatCurrencyPreference, setFundingAccounts} = useStore()
	const {selectedCryptoCurrencyName} = useSelectedCryptoAccountInfo()
	const {response, request, loading, error} = ApiHook(() =>
		embeddedPartnerApi.fetchFundingAccounts(fiatCurrencyPreference as any),
	)

	useEffect(() => {
		if (error) {
			showTransactionErrorDialog()
		}
	}, [error])

	useEffect(() => {
		setFundingAccounts(response || [])
	}, [response])

	useEffect(() => {
		request()
	}, [])

	return (
		<Page
			loading={loading}
			withBackIcon
			titleHeader={
				transactionType === TransactType.SELL
					? `Sell ${selectedCryptoCurrencyName}`
					: `Buy ${selectedCryptoCurrencyName}`
			}
		>
			<Main>
				<H3 data-testid={'section-title'} style={{marginTop: 50, marginBottom: 20}}>
					{transactionType === TransactType.SELL ? t('label.destination') : t('label.fundingSource')}
				</H3>
				{response?.map(account => (
					<FundingAccount key={account.externalAccountRef} source={account} />
				))}
			</Main>
		</Page>
	)
}
