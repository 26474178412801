import {useState, useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {PartnerParty} from '@bakkt/api'
import {useTimer} from '@bakkt/components'
import {showOnboardingErrorDialog} from 'components/ErrorDialog'
import {useStore} from 'store'

const {fetchPartnerPartyProfile} = embeddedPartnerApi

const RETENTION_TIME = 30000

export const useTaxIdentifier = () => {
	const [taxIdentifier, setTaxIdentifier] = useState<PartnerParty['taxIdentifier'] | null>(null)
	const {request, response, error, loading} = ApiHook(() => fetchPartnerPartyProfile(true))
	const setTimer = useTimer(RETENTION_TIME, () => setTaxIdentifier(''))
	const {partner} = useStore()

	useEffect(() => {
		if (response) {
			setTaxIdentifier(response?.Party?.additionalAttributes?.tin || '')
			setTimer()
		}
	}, [response])

	useEffect(() => {
		if (error) showOnboardingErrorDialog(partner)
	}, [error])

	return {
		taxIdentifier,
		loading,
		getTaxIdentifier: request,
		resetTaxIdentifier: () => setTaxIdentifier(''),
	}
}
