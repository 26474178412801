import styled from '@emotion/styled'
import React from 'react'

interface ImageProps {
	url: string
}

export const Image = styled.div<ImageProps>`
	background: url('${props => props.url}') top center / contain no-repeat;
	margin: 0px auto 0;
	padding-bottom: 30%;
`
