import styled from '@emotion/styled'
import {useTheme} from '@material-ui/core'
import {ReactNode} from 'react'
import {Spinner} from '../loading/Spinner'
import {Header} from './Header'

const FullHeight = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	min-height: 100%;
`

const Center = styled.div`
	margin: auto;
`

export type PageProps = {
	children?: ReactNode
	loading?: boolean
	onIconPress?: () => void
	titleHeader?: string
	TitleHeaderComp?: ReactNode
	withBackIcon?: boolean
	withSetting?: boolean
}

export const Page = (props: PageProps) => {
	const {children, loading} = props
	const theme = useTheme()

	return (
		<FullHeight
			id='page'
			role='main'
			style={{
				background: theme?.palette?.background?.default || 'white',
				color: theme?.palette?.text?.primary || '#100722',
			}}>
			<Header {...props} />

			{loading ? (
				<Center>
					<Spinner />
				</Center>
			) : (
				<FullHeight>{children}</FullHeight>
			)}
		</FullHeight>
	)
}
