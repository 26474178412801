import styled from '@emotion/styled'
import React from 'react'
import {color} from '../../color/color'
import {ShadowContainer} from '../shadowContainer/ShadowContainer'
import {BrownPro, InputLabel} from '../text/Text'
export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	label: string
	disabled?: boolean
}
const TextAreaInput = styled.textarea`
font-family: ${BrownPro}
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 130.02%;
letter-spacing: -0.0025em;
color: ${color.blue900};
mix-blend-mode: normal;
border:none;
outline-color: white;
-webkit-appearance: none;
`

export const TextArea = (props: TextAreaProps) => {
	const {children, label, ...rest} = props
	return (
		<ShadowContainer style={{padding: 16, display: 'flex', flexDirection: 'column'}}>
			<InputLabel>{label}</InputLabel>
			<TextAreaInput style={{resize: 'none', flexGrow: 1, width: '100%'}} {...rest} disabled={props?.disabled}>
				{children}
			</TextAreaInput>
		</ShadowContainer>
	)
}
