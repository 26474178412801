import {useEffect} from 'react'
import {Main} from 'components/Layout'
import {useTranslation} from 'react-i18next'
import CryptoAccountsItem from './CryptoAccountItem'
import {useNavigate} from 'react-router-dom'
import {RoutesName} from 'constants/routes'
import {useStore} from 'store'
import {Page, H3, color, VBox, Large} from '@bakkt/components'
import {TestDialog} from 'components/TestDialog'
import {useCryptoAccount} from 'hooks'
import {showSuspendedErrorDialog} from 'components/ErrorDialog'

export const SelectCryptoAccount = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {
		setSelectedCryptoAccount,
		partner,
		setSelectedCryptoAccountId,
		cryptoAccounts: accounts,
		isSuspended,
	} = useStore()
	const {loading} = useCryptoAccount(true)

	function handleSelectCryptoAccount(index: number) {
		if (accounts) {
			setSelectedCryptoAccount(accounts[index])
			setSelectedCryptoAccountId(accounts[index]?.accountId)
			navigate(RoutesName.crypto.overview)
		}
	}

	useEffect(() => {
		if (isSuspended) {
			showSuspendedErrorDialog(partner)
		}
	}, [isSuspended])

	return (
		<Page
			withBackIcon
			onIconPress={() => window.history.go(-1)}
			titleHeader={t('crypto.cryptoAccounts')}
			loading={loading}
		>
			<TestDialog />
			<VBox style={{paddingTop: '2em'}}>
				<Main>
					<H3 data-testid={'section-title'} style={{fontWeight: 700, marginBottom: '30px'}}>
						{t('action.myCrypto')}
					</H3>
					{accounts && accounts.length > 0 ? (
						<>
							{(accounts || []).map((item, index) => (
								<CryptoAccountsItem
									key={index}
									onClick={() => handleSelectCryptoAccount(index)}
									accountBalance={item?.accountBalance}
									indicativeBalance={item?.indicativeBalance}
								/>
							))}
						</>
					) : (
						<Large> No Crypto Accounts Found</Large>
					)}
				</Main>
			</VBox>
		</Page>
	)
}
