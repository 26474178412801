import React, {useState} from 'react'
import {AreaChart, Area, ResponsiveContainer, Tooltip, TooltipProps} from 'recharts'
import {NameType, ValueType} from 'recharts/types/component/DefaultTooltipContent'
import {makeStyles} from '@material-ui/styles'
import {useTheme} from '@material-ui/core'

import {color} from 'src'
import {VBox} from '../container/VBox'
import {InputLabel} from '../text/Text'
import {Value} from '../value'

const useThemeColor = () => {
	const theme = useTheme()

	return {
		gridColor: '#f3f3fb',
		chartControlText: theme?.palette?.text?.background || 'black',
		chartControlBackground: theme?.palette?.background.default || '#F5FDFF',
		chartStrokeColor: color.crypto,
	}
}

const useStyles = makeStyles(() => {
	const colors = useThemeColor()
	return {
		chart: {height: '200px', paddingLeft: '16px', paddingRight: '17px'},
		chartControl: {
			padding: '10px 0',
			backgroundColor: colors.chartControlBackground,
			margin: '0 16px',
			display: 'flex',
			justifyContent: 'space-around',
			paddingLeft: '0',
			borderTop: `1px solid ${colors.gridColor}`,
		},
		chartControlItem: {
			listStyle: 'none',
			height: '38px',
			width: '38px',
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			border: '1px solid #b7b5bd',
			backgroundColor: 'transparent',
			fontFamily: "'SF Pro Text'",
			fontSize: '16px',
			color: colors.chartControlText,
			WebkitTapHighlightColor: 'transparent',
			'&.clickActive': {
				backgroundColor: colors.chartControlBackground,
				border: 'none',
				fontWeight: 'bold',
			},
			outline: 0,
			'&:focus': {
				// TODO: get Theme color for focus highlight
				boxShadow: '0 0 0 3pt #71b3ff',
			},
		},
	}
})

export interface TimeSeriesChartProps {
	onTimeRangeChange: Function
	data: {price: number; timeStamp?: string}[]
	timeRangeButtons?: Array<{label: string; value: string}>
	timeRangeButtonActive?: string
}

const BUTTON_ARRAYS = [
	{label: '1D', value: 'DAY'},
	{label: '1W', value: 'WEEK'},
	{label: '1M', value: 'MONTH'},
	{label: '1Y', value: 'YEAR'},
]

export const TimeSeriesChart = (props: TimeSeriesChartProps) => {
	const {data: chartData = [], timeRangeButtons, timeRangeButtonActive} = props
	const classes = useStyles()
	const colors = useThemeColor()
	const theme = useTheme()

	const timeRangeButton = timeRangeButtons || BUTTON_ARRAYS
	const [currentActiveTimeRange, setCurrentActiveTimeRange] = useState(
		timeRangeButtonActive || BUTTON_ARRAYS[0]?.value,
	)

	const onTimeRangeChange = (timeRange: string) => {
		setCurrentActiveTimeRange(timeRange)
		props.onTimeRangeChange(timeRange)
	}

	const CustomTooltip = ({active, payload}: TooltipProps<ValueType, NameType>) => {
		if (!active) return null
		const data = payload?.[0].payload || {}
		const time = new Date(data.time)
		const timeDisplay =
			currentActiveTimeRange === 'DAY'
				? time.toLocaleTimeString()
				: time.toLocaleDateString(undefined, {month: 'short', day: 'numeric', year: undefined})
		return (
			<VBox>
				{data.time && (
					<InputLabel
						data-testid={'tooltip-time'}
						style={{color: theme?.palette?.text?.background || 'grey', transform: 'translateY(13px)'}}>
						{timeDisplay}
					</InputLabel>
				)}
				{data.price && (
					<Value
						data-testid={'tooltip-value'}
						style={{
							color: theme?.palette?.text?.background || 'black',
						}}>{`$${new Intl.NumberFormat().format(data.price)}`}</Value>
				)}
			</VBox>
		)
	}

	const handleKeyPress = (event: any, value: string) => {
		if (event.key === 'Enter') {
			onTimeRangeChange(value)
		}
	}

	return (
		<div>
			<div className={classes.chart}>
				<ResponsiveContainer width={'100%'} height={200}>
					<AreaChart
						data={chartData}
						margin={{
							top: 5,
							right: 0,
							left: 0,
							bottom: 0,
						}}>
						<Area dataKey='price' stroke={colors.chartStrokeColor} strokeWidth={3} fill='url(#bgChart)' />
						<defs>
							<linearGradient id='bgChart' x1='0' y1='0' x2='0' y2='1'>
								<stop offset='5%' stopColor={colors.chartStrokeColor} stopOpacity={0.8} />
								<stop offset='95%' stopColor={colors.chartStrokeColor} stopOpacity={0.1} />
							</linearGradient>
						</defs>
						<Tooltip
							cursor={{
								stroke: theme?.palette?.text?.background || 'grey',
								strokeWidth: 1,
								strokeDasharray: 5,
							}}
							content={CustomTooltip}
						/>
					</AreaChart>
				</ResponsiveContainer>
			</div>
			<ul className={classes.chartControl} data-testid={'chart-control'}>
				{timeRangeButton.map(({label, value}, index) => (
					<li
						data-testid={`chart-select-${label}`}
						tabIndex={0}
						id={`timeRangeButton-${index}`}
						key={index}
						className={[classes.chartControlItem, currentActiveTimeRange === value ? 'clickActive' : null]
							.filter(item => item)
							.join(' ')}
						onClick={() => onTimeRangeChange(value)}
						onKeyPress={event => handleKeyPress(event, value)}>
						{label}
					</li>
				))}
			</ul>
		</div>
	)
}
