import React, {useState, useEffect, useRef, HTMLAttributes} from 'react'
import {color} from 'src/color/color'
import {ShadowContainer} from '../shadowContainer/ShadowContainer'
import styled from '@emotion/styled'
import {ChevronDown, XSmll, Check} from '../icons'
import {Input} from '../input/Input'

interface Option {
	label: string
	// eslint-disable-next-line
	value?: any
}

type OmittedInterface = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>

export interface DropdownProps extends OmittedInterface {
	header?: string
	// eslint-disable-next-line
	data: any[]
	// eslint-disable-next-line
	selectedItem?: any[]
	disabled?: boolean
	// eslint-disable-next-line
	onChange?: (options: any) => void
	height?: string
	multiple?: boolean
	clearable?: boolean
	// eslint-disable-next-line
	labelFunction?: (item: any) => string
	menuItem?: (item: Option, selectedOption: Option[]) => React.ReactNode
	menuContainerStyle?: React.CSSProperties
	dropdownStyle?: React.CSSProperties
}

type PartialProps = {
	selected?: boolean
	disabled?: boolean
	height?: string
}

const DropDownHeader = styled.div<PartialProps>`
	font-family: BrownPro;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: -0.0025em;
	color: ${color.grey700};
	border: none;
	outline: 0;
	background-color: ${color.white};
	cursor: ${(props: PartialProps) => (props.disabled ? 'not-allowed' : 'default')};
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 100px;
	padding: 16px;
	padding-left: 16px;
	padding-right: 16px;
`

const DropDownList = styled.ul<PartialProps>`
	font-family: BrownPro;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	list-style-type: none;
	margin: 0;
	padding: 0;
	left: 0;
	min-width: 200px;
	background-color: white;
	font-weight: bold;
	position: absolute;
	box-shadow: 0px 5px 18px rgba(15, 43, 41, 0.15);
	border-radius: 4px;
	z-index: 9999;
	overflow: auto;
	overflow-x: hidden;
	height: ${(props: PartialProps) => props.height || 'auto'};
`

const ListItem = styled.li<PartialProps>`
	padding: 16px;
	border-bottom: 1px solid ${color.grey100};
	font-family: BrownPro;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: -0.0025em;
	color: ${color.grey700};
	background-color: ${(props: PartialProps) => (props.selected ? color.grey200 : color.white)};
	&:hover {
		background-color: ${color.grey100};
	}
	&:last-child {
		border-bottom: none;
	}
`

const NoDataView = styled.div`
	font-family: SF Pro Text;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${color.grey700};
	padding-top: 16px;
	padding-bottom: 16px;
`

function useDropdownVisible(initialIsVisible: boolean) {
	const [isOpen, setIsOpen] = useState(initialIsVisible)
	const ref = useRef<HTMLUListElement>(null)

	// eslint-disable-next-line
	const handleClickOutside = (event: any) => {
		event?.preventDefault()
		if (ref?.current && !ref.current?.contains(event?.target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true)
		return () => {
			document.removeEventListener('click', handleClickOutside, true)
		}
	}, [])

	return {ref, isOpen, setIsOpen}
}

export const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
	const {
		data = [],
		disabled,
		onChange,
		placeholder = 'Select',
		selectedItem = [],
		height,
		multiple,
		clearable,
		menuItem,
		dropdownStyle,
		menuContainerStyle,
		labelFunction,
	} = props
	const {ref, isOpen, setIsOpen} = useDropdownVisible(false)
	// eslint-disable-next-line
	const convertData = (data: any[]) => {
		const newData = [...data]
		const newOptions: Option[] = []
		// eslint-disable-next-line
		newData.forEach((item: any) => {
			let newOption
			if (labelFunction) {
				newOption = {label: labelFunction(item), value: item}
			} else {
				newOption = {label: item, value: item}
			}
			newOptions.push(newOption)
		})
		return newOptions
	}

	const [options, setOptions] = useState(convertData(data))
	const [selectedOption, setSelectedOption] = React.useState(convertData(selectedItem))

	const showMenu = () => !disabled && setIsOpen(true)

	const onOptionClicked = (option: Option) => {
		handleSelection(option)
		!multiple && setIsOpen(false)
	}

	const handleClear = () => {
		setSelectedOption([])
		onChange && onChange([])
	}

	const isOptionSelected = (option: Option) => {
		return selectedOption.some((item: Option) => item.label === option.label)
	}

	const handleSelection = (option: Option) => {
		let newSelectedItems = [...selectedOption]
		if (multiple) {
			if (isOptionSelected(option)) {
				newSelectedItems = newSelectedItems.filter((item: Option) => item.label !== option.label)
			} else {
				newSelectedItems.push(option)
			}
		} else {
			newSelectedItems = [option]
		}
		setSelectedOption(newSelectedItems)
		const selectedItems = newSelectedItems.map((item: Option) => item.value)
		if (onChange) {
			if (multiple) {
				onChange(selectedItems)
			} else {
				onChange(option.value)
			}
		}
	}

	const commaSeparatedOptions = () => {
		return selectedOption.map((item: Option) => item.label).join()
	}

	return (
		<ShadowContainer style={{position: 'relative', display: 'inline-block', padding: 0}}>
			<DropDownHeader onClick={showMenu} disabled={disabled} style={dropdownStyle}>
				<div>
					<span>{commaSeparatedOptions()}</span>
					{clearable && <XSmll onClick={handleClear} style={{transform: 'scale(.5)'}} tabIndex={0} />}
				</div>
				<ChevronDown style={{transform: 'scale(.5)'}} />
			</DropDownHeader>

			{isOpen && (
				<DropDownList ref={ref} height={height} style={menuContainerStyle}>
					{options.length ? (
						options.map((option: Option) =>
							menuItem ? (
								<div onClick={() => onOptionClicked(option)}>{menuItem(option, selectedOption)}</div>
							) : (
								<ListItem
									onClick={() => onOptionClicked(option)}
									selected={isOptionSelected(option)}
									key={option.label}>
									<div style={{display: 'flex', justifyContent: 'space-between'}}>
										<span>{option.label}</span>
										{clearable && <XSmll style={{transform: 'scale(.5)'}} onClick={handleClear} />}
										{isOptionSelected(option) && multiple && (
											<Check style={{transform: 'scale(.5)'}} />
										)}
									</div>
								</ListItem>
							),
						)
					) : (
						<NoDataView>No Options</NoDataView>
					)}
				</DropDownList>
			)}
		</ShadowContainer>
	)
}
