import {
	ApiEmbeddedPartnerTransactionSummary,
	ApiEmbeddedPartnerTransactionSummaryTransactionTypeEnum as CryptoTransactionTypeEnum,
	ApiAmount,
} from '@bakkt/api'
import {Light, Large, H3, Icons, color, VBox, HBox, CircleIcon} from '@bakkt/components'
import {apiCall, embeddedPartnerApi} from 'api'
import {getCryptoCurrencyDetails} from 'constants/currencies'
import {useNavigate} from 'hooks'
import {t} from 'i18next'
import {useEffect, useState} from 'react'
import {useStore} from 'store'
import {formatCrypto, formatUSD} from 'utils/currencyFormatters'
import {Link} from '@bakkt/components'
import {LinearProgress, makeStyles} from '@material-ui/core'

const fetchTransaction = async (currnetPage: number) => {
	return await apiCall(() => embeddedPartnerApi.getTransactionSummary(undefined, undefined, currnetPage, 10))
}
const useStyles = makeStyles(() => ({
	root: {
		'& .MuiLinearProgress-barColorPrimary': {
			backgroundColor: 'gray',
		},
	},
}))

const TransactionActivity = () => {
	const classes = useStyles()
	const [transactionActivity, setTransactionActivity] = useState<ApiEmbeddedPartnerTransactionSummary[]>([])
	const [pagination, setPagination] = useState<boolean>(true)
	const [loading, setLoading] = useState<boolean>(true)
	const [currnetPage, setCurrentPage] = useState<number>(0)
	const {setActivePartnerTransactionId} = useStore()
	const navigate = useNavigate()

	useEffect(() => {
		;(async () => {
			setLoading(true)
			const {response = []} = await fetchTransaction(currnetPage)
			setLoading(false)
			setTransactionActivity(response)
		})()
	}, [])
	const handleActivityRowClick = (partnerTransactionId: string) => {
		setActivePartnerTransactionId(partnerTransactionId)
		navigate(`/detail`, {ignore: true})
	}

	const handleMore = async () => {
		setLoading(true)
		const {response = []} = await fetchTransaction(currnetPage)
		setLoading(false)
		setCurrentPage(old => old + 1)
		setPagination(response?.length === 10 ? true : false)
		setTransactionActivity(old => [...old, ...response])
	}
	return (
		<div className='activityWrapper'>
			{(transactionActivity || []).length > 0 && (
				<>
					<H3>{t('activity.main')}</H3>
					<VBox>
						{(transactionActivity || []).map((element, index) => (
							<div
								className='transactionHistory'
								style={{cursor: 'pointer'}}
								onClick={() => handleActivityRowClick(element?.transactionId)}
								key={element?.transactionId}
							>
								{element?.transactionType === CryptoTransactionTypeEnum.BUY && (
									<ActivityRow
										icon={Icons.BuyCrypto}
										transactionLabel={`${t('purchased')}`}
										cryptoQuantity={element?.target}
										fiatAmount={element?.source}
										showBorder={index !== transactionActivity.length - 1}
									/>
								)}
								{element?.transactionType === CryptoTransactionTypeEnum.SELL && (
									<ActivityRow
										icon={Icons.SellCrypto}
										transactionLabel={`${t('sold')}`}
										cryptoQuantity={element?.source}
										fiatAmount={element?.target}
										showBorder={index !== transactionActivity.length - 1}
									/>
								)}
							</div>
						))}
						{!loading && pagination && <Link onClick={handleMore}>More</Link>}
					</VBox>
				</>
			)}
			{loading && <LinearProgress className={classes.root} />}
		</div>
	)
}

export type ActivityRowProps = {
	icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
	transactionLabel: string
	cryptoQuantity: ApiAmount
	fiatAmount: ApiAmount
	showBorder?: boolean
}

export const ActivityRow = ({icon, transactionLabel, cryptoQuantity, fiatAmount, showBorder}: ActivityRowProps) => {
	const currencyDetails = getCryptoCurrencyDetails(cryptoQuantity?.currency)
	return (
		<HBox
			style={{
				alignItems: 'center',
				padding: '16px 0px',
				borderBottom: showBorder ? '1px solid #e7e6e9' : undefined,
			}}
		>
			<HBox style={{alignItems: 'center'}}>
				{icon && (
					<CircleIcon
						data-testid={'activity-icon'}
						icon={icon}
						iconColor={color.black}
						style={{background: color.crypto, marginRight: 8, padding: 10}}
					/>
				)}
				<Large data-testid={'activity-description'}>{`${transactionLabel} ${currencyDetails?.name}`}</Large>
			</HBox>

			<VBox style={{marginLeft: 'auto'}}>
				<Light data-testid={'activity-value-crypto'} style={{margin: 0}}>
					{formatCrypto(cryptoQuantity?.amount || 0, cryptoQuantity?.currency)}
				</Light>
				<Light data-testid={'activity-value-usd'} style={{margin: 0}}>
					{formatUSD(fiatAmount?.amount, fiatAmount?.currency)}
				</Light>
			</VBox>
		</HBox>
	)
}

export default TransactionActivity
