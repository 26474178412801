import React from 'react'
import {BrownPro, color, List, ListItem, ListItemProps, useTheme} from '@bakkt/components'
import {Theme} from '@material-ui/core'

interface MinimalListProps {
	data: any[]
	capitalize?: boolean
}

export const MinimalList = ({data, capitalize}: MinimalListProps) => {
	const theme = useTheme() as Theme

	return (
		<List
			data={data}
			listItem={(item: ListItemProps) => (
				<ListItem
					style={{padding: '11px 1px 8px'}}
					contentRightProps={{
						style: {
							fontFamily: theme?.typography?.fontFamily || BrownPro,
							textTransform: capitalize ? 'capitalize' : 'none',
						},
					}}
					{...item}
				/>
			)}
			disableSelection
			containerProps={{
				style: {
					width: '100%',
					boxShadow: 'none',
					padding: '0',
				},
			}}
		/>
	)
}
