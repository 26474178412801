import React from 'react'
import {Button as BakktButton, ButtonProps, color} from '@bakkt/components'

const Button = ({children, ...props}: ButtonProps) => (
	<BakktButton fullWidth size='large' {...props}>
		{children}
	</BakktButton>
)

export default Button
