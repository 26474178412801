import styled from '@emotion/styled'
import {ReactNode, useContext} from 'react'
import {color} from '../../color/color'
import {Backward, Settings} from '../icons'
import {HistoryContext} from '../navigation/MemoryRouter'
import {useTheme} from '@material-ui/core'

export const HeaderContainer = styled.div`
	position: relative;
	padding: 2% 5%;
	background: ${props => props?.theme?.palette?.primary?.main || color.blueKolibri};
`

const TitleHeader = styled.h1`
	display: flex;
	justify-content: center;
	color: ${props => props?.theme?.palette?.primary?.contrastText || '#fff'};
	font-size: 18px;
	font-weight: normal;
`

const HeaderRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: 3rem;
`

const HeaderRowStart = styled.div`
	flex: 0.3;
	align-self: center;
	display: flex;
`

const HeaderRowEnd = styled.div`
	flex: 0.3;
	justify-content: flex-end;
	display: flex;
`

export type HeaderProps = {
	loading?: boolean
	onIconPress?: () => void
	titleHeader?: string
	TitleHeaderComp?: ReactNode
	withBackIcon?: boolean
	withSetting?: boolean
}

export const Header = ({
	loading,
	onIconPress,
	titleHeader,
	TitleHeaderComp,
	withBackIcon,
	withSetting,
}: HeaderProps) => {
	const history = useContext(HistoryContext)
	const theme = useTheme()
	const titleHeaderId = titleHeader?.toLocaleLowerCase().replace(/\s/g, '-')

	const onIconClick = () => {
		if (!onIconPress && withBackIcon) history?.go(-1)
		else onIconPress?.()
	}

	const handleKeyPress = (event: any, value: any) => {
		if (event.key === 'Enter') {
			value()
		}
	}

	return (
		<HeaderContainer>
			<HeaderRow id='header-navigation-container'>
				{!loading && (
					<>
						<HeaderRowStart
							onClick={onIconClick}
							onKeyPress={event => handleKeyPress(event, onIconClick)}
							id='Header_Row_Left_Icons'
							data-testid={withBackIcon ? 'header-back-navigation' : 'header-navigation-start'}>
							{withBackIcon && (
								<Backward
									fill={theme?.palette?.primary?.contrastText || '#fff'}
									style={{cursor: 'pointer'}}
									tabIndex={0}
								/>
							)}
						</HeaderRowStart>

						{TitleHeaderComp
							? TitleHeaderComp
							: titleHeader && (
									<TitleHeader id={`${titleHeaderId}-text`} data-testid={'header-navigation-title'}>
										{titleHeader}
									</TitleHeader>
							  )}

						<HeaderRowEnd>{withSetting && <Settings style={{marginTop: '10px'}} />}</HeaderRowEnd>
					</>
				)}
			</HeaderRow>
		</HeaderContainer>
	)
}
