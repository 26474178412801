import {HTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {useNavigate} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {color as c, BrownPro, Icons} from '@bakkt/components'

export const TransitionRouter = ({children}: {children: JSX.Element[]}) => (
	// <Location>
	//   {({location}) => (
	//     <TransitionGroup className="transition-group">
	//       <CSSTransition key={location.key} classNames="fade" timeout={200}>
	//         {/* the only difference between a router animation and
	//             any other animation is that you have to pass the
	//             location to the router so the old screen renders
	//             the "old location" */}
	//         <Router location={location} className="router">
	//           {props.children}
	//         </Router>
	//       </CSSTransition>
	//     </TransitionGroup>
	//   )}
	// </Location>
	<div
		style={{
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
		}}
	>
		{children}
	</div>
)

export const GoogleRecaptchaBadge = () => (
	<span
		style={{
			fontFamily: BrownPro,
			textAlign: 'left',
			fontSize: '.9em',
			color: 'gray',
			marginTop: 20,
		}}
	>
		This site is protected by reCAPTCHA and the Google{' '}
		<a href='https://policies.google.com/privacy' rel='noopener noreferrer' target='_blank'>
			Privacy Policy
		</a>{' '}
		and{' '}
		<a href='https://policies.google.com/terms' rel='noopener noreferrer' target='_blank'>
			Terms of Service
		</a>{' '}
		apply.
	</span>
)

export const TouchableOpacity = styled.button`
	transition: all ease-out 0.2s;
	border: none;
	padding: 0;
	background: transparent;
	@keyframes pulse {
		0% {
			transform: scale(1);
			opacity: 1;
		}
		50% {
			transform: scale(0.9);
			opacity: 0.7;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}
	&:focus {
		animation: pulse 0.2s 1 ease-in-out;
	}
`

type IconProps = {
	iconProps?: React.SVGProps<SVGSVGElement>
}
export const Logo = ({iconProps, ...rest}: IconProps & React.HTMLAttributes<HTMLDivElement>) => (
	<Link to='/'>
		<div className='logo' {...rest}>
			<Icons.BakktLogo {...iconProps} />
		</div>
	</Link>
)

export const Screen = styled.div`
	height: 100%;
	display: flex;
	flex: 1;
	padding-left: 7%;
	padding-right: 7%;
	width: 100%;
	max-width: 660px;
	margin: 0 auto;
`

interface MainProps extends HTMLAttributes<HTMLDivElement> {
	center?: boolean
	centerText?: boolean
}
export const Main = styled.div<MainProps>`
	padding-left: 7%;
	padding-right: 7%;
	width: 86%;
	max-width: 660px;
	margin: 0 auto;
	text-align: ${props => (props.centerText || props.center ? 'center' : 'initial')};
	flex-grow: ${props => (props.center ? 1 : 0)};
	display: ${props => (props.center ? 'flex' : 'block')};
	justify-content: ${props => (props.center ? 'center' : 'flex-start')};
	flex-direction: ${props => (props.center ? 'column' : 'row')};
`

export const ContainerRow = styled.div`
	display: flex;
	flex-direction: row;
`

export const ContainerColumn = styled.div`
	display: flex;
	flex-direction: column;
`

export const PatternPage = styled.div`
	background: #fff url(/action-success-bg.png) repeat center top;
	text-align: center;
	padding-left: 7%;
	padding-right: 7%;
	padding-top: 7%;
	width: 100%;
	min-height: 77vh;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const BottomBox = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	margin-top: auto;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 7%;
	padding-right: 7%;
	width: 86%;
	max-width: 660px;
	text-align: center;
	align-items: center;
	justify-content: center;
`

export const HeaderRight = styled.div`
	position: absolute;
	top: 2%;
	right: 7%;
	margin-top: 12px;
	height: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const SolidHeader = styled.div`
	position: absolute;
	background: #fff;
	top: 0;
	left: 0;
	right: 0;
	height: 105px;
	padding: 0 7%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
`

export const XButton = styled.button`
	border: none;
	box-shadow: none;
	outline: none;
	cursor: pointer;
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url(/X.svg) no-repeat center center / contain;
`

export const BackX = () => {
	const navigate = useNavigate()
	return <XButton style={{position: 'absolute', right: '7%'}} onClick={() => navigate(-1)}></XButton>
}

export const SmallCircleButton = styled.button`
	border-radius: 50%;
	width: 24px;
	height: 24px;
	background: #fff;
	border: 1px solid ${c.grey40};
	display: flex;
	justify-content: center;
	align-items: center;
`

export const ClearButton = styled.button`
	background: none;
	border: none;
	&:not(:focus) {
		outline: none;
	}
`

export const IconHolder = styled.div`
	border-radius: 50%;
	margin: 0 auto;
	background: ${c.green200};
	display: flex;
	justify-content: center;
	width: 104px;
	height: 104px;
	img {
		width: 35px;
	}
`
