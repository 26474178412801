import {ApiAmountCurrencyEnum, ApiEmbeddedFundingAccount} from '@bakkt/api'
import {stripHtml} from 'string-strip-html'
import {formatUSD} from './currencyFormatters'
import {Application as BakktComponentApplication} from '@bakkt/components'

import moment from 'moment-timezone'

const emailRegex =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isValidEmail = (email: string) => emailRegex.test(email)

export const sanitizeSignature = (signature: string | null | undefined): string => {
	if (typeof signature !== 'string') {
		return ''
	}
	return stripHtml(signature)
		.result.replace(/[^\w\s]/gi, '')
		.slice(0, 32)
		.trim()
}

export const formatFundingSourceInfo = (source: ApiEmbeddedFundingAccount | null) => {
	return source
		? `${formatUSD(source?.availableBalance?.amount, ApiAmountCurrencyEnum.USD)} | ${
				source?.formattedAccountNumber
		  }`
		: ''
}

export const formatDate = (date: any) => {
	const timezone = BakktComponentApplication.timezone
	if (date && timezone && moment(date).isValid()) {
		const formattedDate = moment(date).tz(timezone).format('MM/DD/YYYY')
		const formattedTime = moment(date).tz(timezone).format('HH:mm:ss A')
		const timeZoneAbbr = moment.tz(timezone).zoneAbbr()
		return {formattedDate, formattedTime, timeZoneAbbr}
	}
	return {}
}
export const removeUnderScoreAndDeCapitalize = (text: string) => {
	return text.split('_').join(' ').toLowerCase()
}

export const nameFormatter = (firstName: string | undefined, lastName: string | undefined) => {
	if (firstName && lastName) return firstName + ' ' + lastName
	return firstName || lastName || ''
}

export const formatSSN = (ssn: string | number | undefined): string => {
	if (!ssn) return ''

	ssn = ssn.toString()
	if (ssn.includes('-')) return ssn
	return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`
}

export const formatMaskedSSN = (ssn: string | number | undefined): string => {
	if (!ssn) return ''

	ssn = ssn.toString()
	if (ssn.includes('-') && ssn.split('-').length > 1) return `xxx-xx-${ssn.split('-')[2]}`
	return `xxx-xx-${ssn.substring(5)}`
}
