import React, {AnchorHTMLAttributes} from 'react'
import styled from '@emotion/styled'
import {color} from 'src/color/color'

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	color?: string
}

const DefaultLink = styled.a`
	font-family: BrownPro;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 150%;
	text-decoration-line: underline;
	cursor: pointer;
	color: ${props => props.color || props?.theme?.palette?.text?.newLink || color.blue900};
	flex: none;
	order: 0;
	align-self: center;
	flex-grow: 0;
	margin: 10px 0px;
	&:visited {
		color: ${props => props?.theme?.palette?.text?.visitedLink || color.blue900};
	}
`

export const Link: React.FC<LinkProps> = ({children, ...prop}) => <DefaultLink {...prop}>{children}</DefaultLink>
