import {ThemeProvider as EmotionThemeProvider} from '@emotion/react'
import {
	ClassNameMap,
	Styles,
	WithStylesOptions,
	useTheme as useMuiTheme,
	makeStyles as makeMuiStyles,
} from '@material-ui/styles'
import {ThemeProvider as MUIThemeProvider, ThemeOptions, Theme as BakktTheme} from '@material-ui/core'
import {createTheme} from '@material-ui/core/styles'
import _ from 'lodash'

export function useTheme<BakktTheme>(): BakktTheme {
	return useMuiTheme()
}

export function makeStyles<Props extends object = {}, ClassKey extends string = string>(
	styles: Styles<BakktTheme, Props, ClassKey>,
	options?: Omit<WithStylesOptions<BakktTheme>, 'withTheme'>,
): keyof Props extends never // `makeStyles` where the passed `styles` do not depend on props
	? (props?: any) => ClassNameMap<ClassKey> // `makeStyles` where the passed `styles` do depend on props
	: (props: Props) => ClassNameMap<ClassKey> {
	return makeMuiStyles(styles, options)
}

export interface ThemeApi {
	backgroundColor?: string
	backgroundFontColor?: string
	font?: string
	primaryColor?: string
	primaryFontColor?: string
	secondaryColor?: string
	secondaryFontColor?: string
	tertiaryColor?: string
	tertiaryFontColor?: string
	unvisitedLinkColor?: string
	visitedLinkColor?: string
}

export const createThemeFromApi = (apiSource?: ThemeApi, fallbackTheme: ThemeOptions = {}): ThemeOptions => {
	const theme = apiSource
		? {
				palette: {
					background: {
						default: apiSource.backgroundColor,
					},
					primary: {
						main: apiSource.primaryColor,
						contrastText: apiSource.primaryFontColor,
					},
					secondary: {
						main: apiSource.secondaryColor,
						contrastText: apiSource.secondaryFontColor,
					},
					tertiary: {
						main: apiSource.tertiaryColor,
						contrastText: apiSource.tertiaryFontColor,
					},
					text: {
						background: apiSource.backgroundFontColor,
						primary: apiSource.primaryFontColor,
						secondary: apiSource.secondaryFontColor,
						tertiary: apiSource.tertiaryFontColor,
						newLink: apiSource.unvisitedLinkColor,
						visitedLink: apiSource.visitedLinkColor,
					},
				},
				typography: {
					fontFamily: apiSource.font,
				},
		  }
		: {}

	return _.merge({}, fallbackTheme, theme)
}

export interface ThemeProviderProps {
	children: React.ReactNode
	theme: ThemeOptions
}

export let CurrentTheme = {} as BakktTheme
export const BakktThemeProvider = ({theme, children}: ThemeProviderProps) => {
	CurrentTheme = createTheme(theme)
	return (
		<MUIThemeProvider theme={CurrentTheme}>
			<EmotionThemeProvider theme={CurrentTheme}>{children}</EmotionThemeProvider>
		</MUIThemeProvider>
	)
}
