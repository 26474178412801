import React, {HTMLAttributes} from 'react'
import {TagProps, Tag} from 'src/components/tag/Tag'
import {color} from 'src/color/color'
import styled from '@emotion/styled'

export interface InfoProps extends HTMLAttributes<HTMLDivElement> {
	text: string
	tag?: TagProps
}

const DefaultInfo = styled.div`
	background: #0f2b29;
	border: 1px solid ${color.success};
	box-sizing: border-box;
	box-shadow: 0px 5px 18px rgba(15, 43, 41, 0.15);
	border-radius: 8px;
	align-items: center;
	display: flex;
	justify-content: flex-start;
	padding: 25px 30px;
`

const Text = styled.span`
	font-family: BrownPro;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 130.02%;
	letter-spacing: -0.0025em;
	color: ${color.white};
	padding-left: 15px;
`

export const Info: React.FC<InfoProps> = ({text = '', tag, ...props}: InfoProps) => (
	<DefaultInfo {...props}>
		{tag && <Tag {...tag} />}
		<Text>{text}</Text>
	</DefaultInfo>
)
