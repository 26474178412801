import {getCryptoCurrencyDetails} from 'constants/currencies'
import {useStore} from 'store'

export const useSelectedCryptoAccountInfo = () => {
	const {selectedCryptoAccount} = useStore()
	const selectedCryptoCurrency = selectedCryptoAccount?.accountBalance?.currency
	const cryptoCurrencyDetails = getCryptoCurrencyDetails(selectedCryptoCurrency)

	const selectedCryptoCurrencyName = cryptoCurrencyDetails?.name || selectedCryptoCurrency
	const selectedCryptoCurrencyIcon = cryptoCurrencyDetails?.icon
	const selectedCryptoCurrencyPrecision = cryptoCurrencyDetails?.precision

	return {
		selectedCryptoCurrency,
		selectedCryptoCurrencyName,
		selectedCryptoCurrencyIcon,
		selectedCryptoCurrencyPrecision,
	}
}
