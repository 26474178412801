import {ReactNode, useMemo} from 'react'
import {defaultLightTheme, BakktThemeProvider, createThemeFromApi} from '@bakkt/components'

import {useStore} from 'store'

type Props = {
	children: ReactNode
}

export const EmbeddedThemeProvider = ({children}: Props) => {
	const {partner} = useStore()

	const currentTheme = useMemo(() => {
		return createThemeFromApi(partner?.lightTheme, defaultLightTheme)
	}, [partner])

	return <BakktThemeProvider theme={currentTheme}>{children}</BakktThemeProvider>
}
