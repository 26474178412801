export enum SessionStorageKeys {
	IsLoggedIn = 'IS_LOGGED_IN',
	PARTNER = 'partner',
	FAKE_SSN = 'fake.ssnNumber',
	FAKE_ERROR = 'X-Fake-Error',
	FAKE_ERROR_CODE = 'X-Fake-Error-Code',
}

export enum SessionStorageDataType {
	STRING = 'string',
	JSON = 'json',
}

export type SessionStorageProperties = {
	[Key in SessionStorageKeys]: SessionStorageDataType
}

export const SessionStorageValues: SessionStorageProperties = {
	[SessionStorageKeys.IsLoggedIn]: SessionStorageDataType.JSON,
	[SessionStorageKeys.PARTNER]: SessionStorageDataType.JSON,
	[SessionStorageKeys.FAKE_SSN]: SessionStorageDataType.JSON,
	[SessionStorageKeys.FAKE_ERROR]: SessionStorageDataType.STRING,
	[SessionStorageKeys.FAKE_ERROR_CODE]: SessionStorageDataType.STRING,
}

export enum ActivatedCryptoDebitCard {
	Activated = 'ACTIVATED_CRYPTO_DEBIT_CARD',
}
