import React, {SetStateAction} from 'react'
export interface Filter {
	id: number
	fieldName: string
	fieldValues: string[]
	searchCriteria: SearchCriteria
	isUndeletable?: boolean
	isDefaultFilter?: boolean
	filterType: 'set' | 'date' | 'number' | 'text' | 'datetime' | 'guid'
}

export interface FilterField {
	dataField: string
	headerText: string
	filterType: 'set' | 'date' | 'number' | 'text' | 'datetime' | 'guid'
	filterValues?: string[]
	filterCriterias?: SearchCriteria[]
	dateRangeValidatorFn?: (
		filterStartDate: Date | string | undefined,
		filterEndDate: Date | string | undefined,
	) => boolean
}

export enum SearchCriteria {
	AFTER = 'AFTER',
	AFTERORNULL = 'AFTER_OR_NULL',
	BEFORE = 'BEFORE',
	BEFOREORNULL = 'BEFORE_OR_NULL',
	BETWEEN = 'BETWEEN',
	RELATIVETIME = 'RELATIVE_TIME',
	CONTAINS = 'CONTAINS',
	ENDSWITH = 'ENDS_WITH',
	EQUALS = 'EQUALS',
	GREATERTHAN = 'GREATER_THAN',
	GREATERTHANOREQUAL = 'GREATER_THAN_OR_EQUAL',
	IN = 'IN',
	INRANGE = 'IN_RANGE',
	LESSTHAN = 'LESS_THAN',
	LESSTHANOREQUAL = 'LESS_THAN_OR_EQUAL',
	NOTCONTAINS = 'NOT_CONTAINS',
	NOTEQUALS = 'NOT_EQUALS',
	STARTSWITH = 'STARTS_WITH',
}

export enum RelativeTime {
	LAST7DAYS = 'LAST_7_DAYS',
	LAST14DAYS = 'LAST_14_DAYS',
	LAST30DAYS = 'LAST_30_DAYS',
	LAST90DAYS = 'LAST_90_DAYS',
}

export const FilterType = {
	set: [SearchCriteria.EQUALS],
	date: [
		SearchCriteria.BETWEEN,
		SearchCriteria.RELATIVETIME,
		SearchCriteria.AFTER,
		SearchCriteria.AFTERORNULL,
		SearchCriteria.BEFORE,
		SearchCriteria.BEFOREORNULL,
	],
	text: [
		SearchCriteria.STARTSWITH,
		SearchCriteria.NOTCONTAINS,
		SearchCriteria.EQUALS,
		SearchCriteria.NOTEQUALS,
		SearchCriteria.CONTAINS,
		SearchCriteria.ENDSWITH,
	],
	guid: [SearchCriteria.EQUALS],
	number: [
		SearchCriteria.INRANGE,
		SearchCriteria.LESSTHAN,
		SearchCriteria.LESSTHANOREQUAL,
		SearchCriteria.GREATERTHAN,
		SearchCriteria.GREATERTHANOREQUAL,
	],
	datetime: [
		SearchCriteria.BETWEEN,
		SearchCriteria.RELATIVETIME,
		SearchCriteria.AFTER,
		SearchCriteria.AFTERORNULL,
		SearchCriteria.BEFORE,
		SearchCriteria.BEFOREORNULL,
	],
}
