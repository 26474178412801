import {VBox, HBox, color, Icons, Light, useTheme} from '@bakkt/components'
import {Theme} from '@material-ui/core'

type Props = {
	label: string
	fundingName?: string
	fundingDetails?: string
	icon?: JSX.Element | null
	onClick?: () => void
	style?: React.CSSProperties
	dataTestId?: string
}

//TODO:  Move to shared components
export const FundingRow = ({label, fundingName, fundingDetails, icon, onClick, style, dataTestId}: Props) => {
	const theme = useTheme() as Theme
	return (
		<div
			data-testid={`${dataTestId}-row`}
			style={{cursor: onClick ? 'pointer' : 'default'}}
			onClick={() => onClick && onClick()}
		>
			<HBox
				style={{
					borderBottom: '1px solid',
					borderBottomColor: theme?.palette?.text?.background || color.grey100,
					padding: '0.5rem',
					minHeight: '50px',
					...style,
				}}
			>
				<HBox style={{minWidth: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
					<Light data-testid={`${dataTestId}-row-label`}>{label}</Light>
					<HBox>
						<VBox>
							{fundingName && (
								<HBox style={{flexDirection: 'row-reverse'}}>
									<Light
										data-testid={`${dataTestId}-primary-text`}
										style={{margin: '2px 0 4px 4px', textAlign: 'right', fontSize: '12px'}}
									>
										{fundingName}
									</Light>
									{icon}
								</HBox>
							)}

							<Light
								data-testid={`${dataTestId}-secondary-text`}
								style={{margin: '0', textAlign: 'right', fontSize: '12px'}}
							>
								{fundingDetails}
							</Light>
						</VBox>
						{onClick && (
							<Icons.ChevronRightSmll
								id='funding-row-right-chevron'
								style={{
									alignSelf: 'center',
									paddingLeft: '0.4rem',
								}}
								fill={theme?.palette?.text?.background || color.white}
							/>
						)}
					</HBox>
				</HBox>
			</HBox>
		</div>
	)
}
