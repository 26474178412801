import {useState, useEffect} from 'react'
import {embeddedPartnerApi, ApiHook} from 'api'
import {HistoricalPrice} from '@bakkt/api'
import {useStore, HistoricalPeriod} from 'store'

type UseCryptoHistoricalCurrency = {
	historicalCryptoCurrency: Array<HistoricalPrice>
	changeTimeRange: (timeRange: HistoricalPeriod) => void
}

const {getHistoricalCryptoCurrencyPrice} = embeddedPartnerApi

export const useCryptoHistoricalCurrency = (): UseCryptoHistoricalCurrency => {
	const {selectedCryptoAccount, fiatCurrencyPreference} = useStore()
	const [historicalCryptoCurrency, setHistoricalCryptoCurrency] = useState<Array<HistoricalPrice>>([])
	const [timeRange, setTimeRange] = useState<HistoricalPeriod>('DAY')

	const {request, response} = ApiHook(() =>
		getHistoricalCryptoCurrencyPrice(
			timeRange,
			selectedCryptoAccount?.accountBalance?.currency as any,
			fiatCurrencyPreference as any,
		),
	)
	const changeTimeRange = (timeRangeChange: HistoricalPeriod = 'DAY') => {
		setTimeRange(timeRangeChange)
	}

	useEffect(() => {
		// This data is being returned in descending order so we need to reverse it
		// we also want to duplicate the last element in the array so that recharts tooltip
		// will be able to display it due to an issue in that package
		const historicalPrices = response?.historicalPrices?.reverse() || []
		if (historicalPrices.length !== 0) {
			const historicalPricesWithDuplicate = [...historicalPrices, historicalPrices[historicalPrices.length - 1]]
			setHistoricalCryptoCurrency(historicalPricesWithDuplicate)
		} else {
			setHistoricalCryptoCurrency(historicalPrices)
		}
	}, [response])

	useEffect(() => {
		request()
	}, [selectedCryptoAccount, timeRange])

	return {
		historicalCryptoCurrency,
		changeTimeRange,
	}
}
