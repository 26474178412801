import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
	Large,
	Light,
	H2,
	Link,
	Checkbox,
	color,
	Page,
	HBox,
	IconLabel,
	Icons,
	Separator,
	useWindowDimensions,
} from '@bakkt/components'
import {Image} from 'components/Image'
import {Main, BottomBox} from 'components/Layout'
import Button from 'components/Button'
import {showDialog} from 'components/Dialog'
import signUpImage from 'images/signUp.svg'

import {useStore} from 'store'
import {RoutesName} from 'constants/routes'
import {TermsOfuse} from './TermsOfUse'
import {PrivacyPolicy} from './PrivacyPolicy'
import {removeUnderScoreAndDeCapitalize} from 'utils/formatters'
import ExitOnboardingButton from '../../../../components/ExitOnboardingButton'
import {useNavigate} from 'hooks'

const SharePersonalInfo = () => {
	const navigate = useNavigate()
	const {t} = useTranslation()
	const {width} = useWindowDimensions()
	const {loadProfile, partyProfile = {}, partner} = useStore()
	const [termsAccepted, setTermsAccepted] = useState(false)

	useEffect(() => {
		loadProfile()
	}, [])

	const availableFields = partyProfile?.fieldsAvailableFromPartner || []
	const missingFields = partyProfile?.missingFields || []

	const navigateToNextScreen = () => {
		if (missingFields?.length > 0) {
			navigate(`${RoutesName.onboarding.home}${RoutesName.onboarding.kyc}`, {ignore: true})
		} else {
			navigate(`${RoutesName.onboarding.home}${RoutesName.onboarding.taxpayer}`, {ignore: true})
		}
	}

	return (
		<Page loading={!partyProfile} titleHeader={t('crypto.accountActivate.activateCrypto')}>
			<Main centerText>
				<img style={{paddingBottom: 0, width: '80%', marginBottom: 20, marginTop: 0}} src={signUpImage} />
				<H2 data-testid={'personal-info-title'}>{t('crypto.accountActivate.yourPersonInfo')}</H2>
				<Large data-testid={'personal-info-description'}>
					{t('crypto.accountActivate.toGetStarted', {partnerName: partner?.name})}
				</Large>
				<Separator />
				<HBox
					style={{
						display: 'grid',
						gridTemplateColumns: `repeat(${width <= 305 ? 1 : 2}, 1fr)`,
						width: 'max-content',
						margin: 'auto',
						gap: '0 1em',
						marginLeft: 'auto',
						marginRight: 'auto',
					}}
				>
					{availableFields.map((item, index) => {
						return (
							<IconLabel
								key={item}
								data-testid={item.toLowerCase().replace(/_/g, '-')}
								style={{flex: '50%', textTransform: 'capitalize'}}
								iconStyle={{transform: 'scale(0.65)'}}
								icon={Icons.Check}
							>
								{removeUnderScoreAndDeCapitalize(item)}
							</IconLabel>
						)
					})}
				</HBox>
				<Separator />
			</Main>
			<BottomBox>
				<Checkbox
					checked={termsAccepted}
					inputProps={{'aria-label': 'Accept terms'}}
					onChange={() => setTermsAccepted(!termsAccepted)}
					label={
						(
							<Light style={{textAlign: 'left'}}>
								{`${t('crypto.accountActivate.enough18')} `}
								<Link
									data-testid={'terms-of-use-link'}
									style={{textDecoration: 'none', fontWeight: 'bold'}}
									onClick={() =>
										showDialog({
											title: '',
											WrappedComponent: TermsOfuse,
											hideSave: true,
											hideCancel: true,
											skip: false,
											fullScreen: true,
										})
									}
									aria-label='Terms of Use'
								>
									{t('more.termsOfUse')}
								</Link>
								{' and '}
								<Link
									data-testid={'privacy-policy-link'}
									style={{textDecoration: 'none', fontWeight: 'bold'}}
									onClick={() => {
										showDialog({
											title: '',
											WrappedComponent: PrivacyPolicy,
											hideSave: true,
											hideCancel: true,
											skip: false,
											fullScreen: true,
										})
									}}
									aria-label='Privacy Policy'
								>
									{t('more.privacyPolicy')}
								</Link>
								.
							</Light>
						) as any
					}
					name='accept-terms'
					className={'checkbox'}
				/>
				<Button
					role='button'
					aria-label='Share With Bakkt'
					className={'sharePersonalInfoBtn'}
					disabled={!termsAccepted || partyProfile?.profileId == null}
					onClick={navigateToNextScreen}
				>
					{t('crypto.accountActivate.bntShareWithBakkt')}
				</Button>
				<ExitOnboardingButton style={{marginBottom: '5px'}} />
			</BottomBox>
		</Page>
	)
}
export default SharePersonalInfo
